import React from "../../_snowpack/pkg/react.js";
import {
  Button,
  Container,
  Icon,
  Spinner,
  Stack,
  Text,
  useToast
} from "../../_snowpack/pkg/@chakra-ui/react.js";
import {
  BookmarkIcon,
  ExclamationCircleIcon
} from "../../_snowpack/pkg/@heroicons/react/24/outline.js";
import {Bookmark} from "./Bookmark.js";
import {getApi} from "../api/api.js";
import {spotifyApi} from "../spotifyApi.js";
import {useQuery} from "../../_snowpack/pkg/react-query.js";
import {Div100Vh} from "./Div100Vh.js";
export const BookmarkList = ({config, token}) => {
  const toast = useToast();
  const {getBookmarks} = getApi({
    baseUrl: config.apiUrl,
    token
  });
  const {
    data: bookmarks,
    isLoading: isBookmarksLoading,
    error: bookmarksError,
    refetch: refetchBookmarks
  } = useQuery("bookmarks", () => getBookmarks(), {
    retry: false
  });
  const handlePlayBookmark = async (bookmark) => {
    try {
      await spotifyApi.pause();
    } catch (err) {
    }
    location.href = "spotify:";
    playBookmark(bookmark);
  };
  const playBookmark = async (bookmark, callCount = 1) => {
    if (callCount > 20) {
      toast({
        status: "error",
        title: "Failed to navigate to the selected bookmark",
        isClosable: true
      });
      return;
    }
    try {
      const {devices} = await spotifyApi.getMyDevices();
      if (devices.length === 0) {
        throw new Error("No devices found");
      }
      if (devices.filter((device) => device.is_active === true).length === 0) {
        await spotifyApi.transferMyPlayback([devices[0].id || ""]);
      }
      await spotifyApi.play({
        uris: [`spotify:${bookmark.type}:${bookmark.trackId}`],
        position_ms: bookmark.progressMs
      });
      return;
    } catch (err) {
    }
    const delay = Math.min(500 * 2 ** Math.floor(callCount / 10), 1e3);
    setTimeout(() => playBookmark(bookmark, callCount + 1), delay);
  };
  const sortedBookmarks = bookmarks ? [...bookmarks].sort((a, b) => a.artistName.localeCompare(b.artistName) * 100 + a.trackName.localeCompare(b.trackName) * 10 + (a.progressMs - b.progressMs) / 1e7) : bookmarks;
  return /* @__PURE__ */ React.createElement(Container, {
    padding: "0"
  }, (() => {
    if (isBookmarksLoading) {
      return /* @__PURE__ */ React.createElement(Div100Vh, {
        w: "full",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }, /* @__PURE__ */ React.createElement(Spinner, {
        size: "lg",
        thickness: "3px",
        speed: "0.75s",
        color: "gray.500"
      }));
    }
    if (bookmarksError || bookmarks === void 0) {
      return /* @__PURE__ */ React.createElement(Div100Vh, {
        w: "full",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }, /* @__PURE__ */ React.createElement(Icon, {
        color: "gray.600",
        as: ExclamationCircleIcon,
        h: "8",
        w: "8"
      }), /* @__PURE__ */ React.createElement(Text, {
        color: "gray.500",
        marginTop: "2"
      }, "Failed to load your bookmarks."), /* @__PURE__ */ React.createElement(Button, {
        marginTop: "4",
        onClick: () => refetchBookmarks()
      }, "Retry"));
    }
    if (bookmarks.length === 0) {
      return /* @__PURE__ */ React.createElement(Div100Vh, {
        w: "full",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }, /* @__PURE__ */ React.createElement(Icon, {
        color: "gray.600",
        as: BookmarkIcon,
        h: "8",
        w: "8"
      }), /* @__PURE__ */ React.createElement(Text, {
        color: "gray.500",
        marginTop: "2"
      }, "You haven't saved any bookmarks yet."));
    }
    return /* @__PURE__ */ React.createElement(Stack, {
      h: "full",
      w: "full",
      alignItems: "center",
      paddingTop: "20",
      paddingBottom: ["64", "56"],
      paddingX: "0",
      spacing: "0"
    }, sortedBookmarks ? sortedBookmarks.map((bookmark) => {
      return /* @__PURE__ */ React.createElement(Bookmark, {
        key: bookmark.id,
        config,
        token,
        bookmark,
        onPlayBookmark: handlePlayBookmark
      });
    }) : "No bookmarks");
  })());
};
