import React, {useState, useEffect} from "../_snowpack/pkg/react.js";
import * as storage from "./storage.js";
import {Login} from "./Login.js";
import {Main} from "./Main.js";
import {spotifyApi} from "./spotifyApi.js";
import {scopes} from "./config.js";
import {Div100Vh} from "./components/Div100Vh.js";
import {Spinner} from "../_snowpack/pkg/@chakra-ui/react.js";
const baseUrl = "https://accounts.spotify.com/authorize";
export const Home = ({config}) => {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();
  const [tokenExpiresAt, setTokenExpiresAt] = useState();
  useEffect(() => {
    const token2 = storage.getToken();
    const tokenExpiresAt2 = storage.getTokenExpiresAt();
    if (token2 && token2.length > 0) {
      if (!tokenExpiresAt2 || tokenExpiresAt2 > Date.now()) {
        spotifyApi.setAccessToken(token2);
        setToken(token2);
        if (tokenExpiresAt2) {
          setTokenExpiresAt(tokenExpiresAt2);
        }
      } else {
        handleLogin();
        return;
      }
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (tokenExpiresAt && Date.now() > tokenExpiresAt) {
        handleLogin();
      }
    }, 1e3);
    return () => clearInterval(interval);
  }, [tokenExpiresAt]);
  const handleLogin = () => {
    const url = new URL(baseUrl);
    url.searchParams.append("client_id", config.clientId);
    url.searchParams.append("response_type", "token");
    url.searchParams.append("redirect_uri", `${window.location.origin}/login-callback`);
    url.searchParams.append("scope", scopes.join(" "));
    location.assign(url.toString());
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(void 0);
  };
  if (loading) {
    return /* @__PURE__ */ React.createElement(Div100Vh, {
      w: "full",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "8"
    }, /* @__PURE__ */ React.createElement(Spinner, {
      size: "lg",
      thickness: "3px",
      speed: "0.75s",
      color: "gray.500"
    }));
  }
  return token ? /* @__PURE__ */ React.createElement(Main, {
    config,
    token,
    onLogout: handleLogout
  }) : /* @__PURE__ */ React.createElement(Login, {
    onLogin: handleLogin
  });
};
