import React, {useState} from "../../../_snowpack/pkg/react.js";
import {
  Container,
  Button,
  Stack,
  Text,
  Icon,
  useToast,
  IconButton,
  Box,
  SlideFade,
  Fade
} from "../../../_snowpack/pkg/@chakra-ui/react.js";
import {
  PlusIcon,
  BookmarkIcon,
  ChevronDownIcon
} from "../../../_snowpack/pkg/@heroicons/react/24/outline.js";
import {getApi} from "../../api/api.js";
import {useMutation, useQuery, useQueryClient} from "../../../_snowpack/pkg/react-query.js";
import {resolveImageUrl, resolveProgressString} from "../../utils.js";
import {spotifyApi} from "../../spotifyApi.js";
import {Track} from "./Track.js";
import {resolvePlayback} from "../../api/playback.js";
const CollapseModalButton = ({
  onClick,
  ...rest
}) => /* @__PURE__ */ React.createElement(IconButton, {
  "aria-label": "close",
  icon: /* @__PURE__ */ React.createElement(Icon, {
    as: ChevronDownIcon,
    h: "5",
    w: "5"
  }),
  backgroundColor: "transparent",
  size: "sm",
  rounded: "full",
  alignSelf: "flex-end",
  onClick,
  _hover: {
    backgroundColor: "gray.700"
  },
  ...rest
});
export const PlaybackModal = ({config, token}) => {
  const [isOpen, setOpen] = useState(true);
  const toast = useToast();
  const {data: playbackResponse} = useQuery("playback", () => spotifyApi.getMyCurrentPlaybackState({additional_types: ["episode"]}), {
    retry: false,
    onError: (err) => toast({
      title: "Failed to load data",
      ...err.responseText ? {description: err.responseText} : {},
      isClosable: true
    })
  });
  const playback = playbackResponse ? resolvePlayback(playbackResponse) : void 0;
  const api = getApi({baseUrl: config.apiUrl, token});
  const queryClient = useQueryClient();
  const {mutate: createBookmark, isLoading: isCreateLoading} = useMutation((payload) => api.createBookmark(payload), {
    onSuccess: () => queryClient.invalidateQueries("bookmarks")
  });
  const handleCreateBookmark = () => {
    if (playback && playback !== "invalid") {
      createBookmark(playback);
    }
  };
  return /* @__PURE__ */ React.createElement(Container, {
    width: "full",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "0",
    alignItems: "center",
    zIndex: 100
  }, /* @__PURE__ */ React.createElement(Fade, {
    in: !isOpen,
    unmountOnExit: true
  }, /* @__PURE__ */ React.createElement(IconButton, {
    "aria-label": "close",
    icon: /* @__PURE__ */ React.createElement(Icon, {
      as: PlusIcon,
      h: "5",
      w: "5"
    }),
    backgroundColor: "green.500",
    size: "lg",
    rounded: "full",
    position: "absolute",
    bottom: ["calc(24px + env(safe-area-inset-bottom))", "8"],
    right: ["6", "6", "-16"],
    onClick: () => setOpen((previous) => !previous),
    _hover: {
      backgroundColor: "green.400"
    }
  })), /* @__PURE__ */ React.createElement(SlideFade, {
    in: isOpen,
    unmountOnExit: true,
    offsetY: "20px"
  }, /* @__PURE__ */ React.createElement(Box, {
    position: "absolute",
    width: "100%",
    bottom: "0",
    paddingX: "2",
    zIndex: 100,
    sx: {
      paddingBottom: "calc(16px + env(safe-area-inset-bottom))"
    }
  }, /* @__PURE__ */ React.createElement(Box, {
    position: "absolute",
    height: "100%",
    left: "0",
    right: "0",
    marginX: "2",
    marginTop: "4",
    backgroundColor: "rgba(23, 25, 34,0.75)",
    backdropFilter: "auto",
    backdropBlur: "4px"
  }), /* @__PURE__ */ React.createElement(Stack, {
    rounded: "xl",
    bgColor: "rgba(27, 32, 43, 0.95)",
    border: "1px solid",
    borderColor: "gray.700",
    width: "100%",
    padding: "4",
    backdropFilter: "auto",
    backdropBlur: "8px"
  }, playback && playback !== "invalid" ? /* @__PURE__ */ React.createElement(Stack, {
    direction: "column",
    spacing: "4"
  }, /* @__PURE__ */ React.createElement(Stack, {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    w: "100%"
  }, /* @__PURE__ */ React.createElement(Text, {
    color: "gray.500",
    fontSize: "sm"
  }, "Currently playing"), /* @__PURE__ */ React.createElement(CollapseModalButton, {
    onClick: () => setOpen((previous) => !previous)
  })), /* @__PURE__ */ React.createElement(Stack, {
    direction: "row",
    alignItems: "center",
    w: "100%",
    spacing: "6",
    flexGrow: 1
  }, /* @__PURE__ */ React.createElement(Track, {
    imageUrl: resolveImageUrl(playback.images) || "",
    trackName: playback.trackName,
    artistName: playback.artistName
  }), /* @__PURE__ */ React.createElement(Text, {
    fontSize: "xl"
  }, resolveProgressString(playback.progressMs))), /* @__PURE__ */ React.createElement(Button, {
    bgColor: "green.500",
    textColor: "white",
    h: "12",
    w: "100%",
    _hover: {
      bgColor: "green.400"
    },
    leftIcon: /* @__PURE__ */ React.createElement(Icon, {
      color: "gray.200",
      as: BookmarkIcon,
      h: "5",
      w: "5"
    }),
    isLoading: isCreateLoading,
    loadingText: "Creating",
    onClick: handleCreateBookmark
  }, "Create bookmark")) : /* @__PURE__ */ React.createElement(Stack, {
    spacing: "2"
  }, /* @__PURE__ */ React.createElement(Stack, {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Text, {
    fontSize: "md",
    fontWeight: "medium",
    color: "gray.100",
    userSelect: "none"
  }, "You aren't listening to any track."), /* @__PURE__ */ React.createElement(CollapseModalButton, {
    onClick: () => setOpen((previous) => !previous)
  })), /* @__PURE__ */ React.createElement(Text, {
    fontSize: "sm",
    color: "gray.400",
    userSelect: "none",
    paddingRight: "12"
  }, "Play a track in Spotify, pause it and return here to create a bookmark."))))));
};
