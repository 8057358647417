import axios from "../../_snowpack/pkg/axios.js";
import {z} from "../../_snowpack/pkg/zod.js";
const Bookmark = z.object({
  type: z.union([z.literal("track"), z.literal("episode")]),
  id: z.string(),
  createdAt: z.string(),
  trackId: z.string(),
  trackName: z.string(),
  artistId: z.string(),
  artistName: z.string(),
  images: z.array(z.object({
    height: z.number(),
    width: z.number(),
    url: z.string()
  })),
  progressMs: z.number()
});
export const getApi = ({baseUrl, token}) => {
  const getBookmarks = () => axios.get(`${baseUrl}/bookmarks`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((response) => z.array(Bookmark).parse(response.data));
  const createBookmark = (payload) => axios.post(`${baseUrl}/bookmarks`, payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const deleteBookmark = (id) => axios.delete(`${baseUrl}/bookmarks/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return {
    getBookmarks,
    createBookmark,
    deleteBookmark
  };
};
