import React from "../../_snowpack/pkg/react.js";
import {Image, Stack, Flex, Icon, IconButton, Box} from "../../_snowpack/pkg/@chakra-ui/react.js";
import {Bars2Icon} from "../../_snowpack/pkg/@heroicons/react/24/outline.js";
export const Header = ({onMenuOpen}) => /* @__PURE__ */ React.createElement(Flex, {
  bgColor: "rgba(27, 32, 43, 0.95)",
  backdropFilter: "auto",
  backdropBlur: "8px",
  height: "16",
  borderBottom: "1px",
  borderColor: "#252b3a",
  paddingX: "4",
  position: "fixed",
  top: "0",
  w: "full",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: 500
}, /* @__PURE__ */ React.createElement(Stack, {
  direction: "row",
  spacing: "2"
}, /* @__PURE__ */ React.createElement(Image, {
  src: "/logo.svg",
  h: "6",
  w: "6",
  fallback: /* @__PURE__ */ React.createElement(Box, {
    h: "6",
    w: "6",
    bg: "rgba(255, 255, 255, 0.05)",
    rounded: "lg"
  })
})), /* @__PURE__ */ React.createElement(Stack, {
  direction: "row",
  spacing: "2"
}, /* @__PURE__ */ React.createElement(IconButton, {
  size: "md",
  "aria-label": "Open menu",
  icon: /* @__PURE__ */ React.createElement(Icon, {
    color: "gray.500",
    as: Bars2Icon,
    h: "5",
    w: "5"
  }),
  onClick: onMenuOpen,
  variant: "ghost"
})));
