import React, {useState} from "../_snowpack/pkg/react.js";
import {
  Heading,
  Icon,
  Button,
  Stack,
  Text,
  Link,
  Center
} from "../_snowpack/pkg/@chakra-ui/react.js";
import {ArrowRightIcon} from "../_snowpack/pkg/@heroicons/react/24/solid.js";
import {SlideFade} from "../_snowpack/pkg/@chakra-ui/transition.js";
import {Box, Image} from "../_snowpack/pkg/@chakra-ui/react.js";
import {Div100Vh} from "./components/Div100Vh.js";
import {Link as RouterLink} from "../_snowpack/pkg/react-router-dom.js";
import {motion} from "../_snowpack/pkg/framer-motion.js";
import {BookmarkCarousel} from "./components/carousel/BookmarkCarousel.js";
const LoginButton = ({
  onLogin,
  ...props
}) => /* @__PURE__ */ React.createElement(Button, {
  as: motion.button,
  bgColor: "green.500",
  size: "md",
  height: "12",
  width: "64",
  flexShrink: 0,
  textColor: "white",
  _hover: {
    bgColor: "#40ac73"
  },
  _active: {
    bgColor: "green.500"
  },
  role: "group",
  rightIcon: /* @__PURE__ */ React.createElement(Icon, {
    color: "gray.200",
    as: ArrowRightIcon,
    h: "4",
    w: "4",
    transition: "transform 0.3s ease-out"
  }),
  whileHover: {
    scale: 1.025
  },
  whileTap: {
    scale: 1
  },
  onClick: onLogin,
  ...props
}, "Login with Spotify");
export const Login = ({onLogin}) => {
  const [showScreen, setShowScreen] = useState(false);
  const handleLogoLoad = () => {
    setShowScreen(true);
  };
  return /* @__PURE__ */ React.createElement(SlideFade, {
    in: showScreen,
    offsetY: "5px"
  }, /* @__PURE__ */ React.createElement(Div100Vh, {
    w: "full",
    display: "flex",
    flexDirection: "column",
    py: "8",
    px: "4"
  }, /* @__PURE__ */ React.createElement(Stack, {
    h: "100%",
    spacing: "6",
    align: "center",
    justify: "space-between"
  }, /* @__PURE__ */ React.createElement(Stack, {
    justifySelf: "flex-start",
    direction: "row",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Box, {
    position: "relative"
  }, /* @__PURE__ */ React.createElement(Image, {
    src: "/logo.svg",
    h: "8",
    w: "8",
    fallback: /* @__PURE__ */ React.createElement(Box, {
      h: "8",
      w: "8",
      bg: "rgba(255, 255, 255, 0.05)",
      rounded: "sm"
    }),
    onLoad: handleLogoLoad
  })), /* @__PURE__ */ React.createElement(Heading, {
    fontSize: "lg",
    fontWeight: "semibold",
    color: "gray.200",
    textAlign: "center"
  }, "Spotify Bookmarks")), /* @__PURE__ */ React.createElement(Stack, {
    alignItems: "center",
    flex: "1",
    w: "100%",
    maxW: "560px",
    maxH: "440px",
    spacing: "0"
  }, /* @__PURE__ */ React.createElement(Heading, {
    as: "h1",
    size: "xl",
    color: "gray.100",
    textAlign: "center",
    lineHeight: "1.1"
  }, "Bookmark specific times in Spotify songs and podcasts"), /* @__PURE__ */ React.createElement(Box, {
    position: "relative",
    flex: "1",
    w: "100%"
  }, /* @__PURE__ */ React.createElement(BookmarkCarousel, {
    position: "absolute",
    top: "0"
  }), /* @__PURE__ */ React.createElement(Box, {
    h: "100%",
    w: "100%",
    position: "absolute",
    bgGradient: "linear-gradient(gray.900 10%, rgba(0,0,0,0), gray.900);"
  }), /* @__PURE__ */ React.createElement(Center, {
    pt: "10",
    position: "absolute",
    w: "100%"
  }, /* @__PURE__ */ React.createElement(LoginButton, {
    onLogin
  })))), /* @__PURE__ */ React.createElement(Stack, {
    justifySelf: "flex-end"
  }, /* @__PURE__ */ React.createElement(Text, {
    fontSize: "sm",
    color: "gray.500",
    textAlign: "center"
  }, "By logging in you accept the", " ", /* @__PURE__ */ React.createElement(Link, {
    as: RouterLink,
    to: "/privacy",
    textDecoration: "underline"
  }, "Privacy Policy"), ".")))));
};
