var extendStatics = function(e, n) {
  return (extendStatics = Object.setPrototypeOf || {__proto__: []} instanceof Array && function(e2, n2) {
    e2.__proto__ = n2;
  } || function(e2, n2) {
    for (var r in n2)
      Object.prototype.hasOwnProperty.call(n2, r) && (e2[r] = n2[r]);
  })(e, n);
};
function __extends(e, n) {
  if (typeof n != "function" && n !== null)
    throw new TypeError("Class extends value " + String(n) + " is not a constructor or null");
  function r() {
    this.constructor = e;
  }
  extendStatics(e, n), e.prototype = n === null ? Object.create(n) : (r.prototype = n.prototype, new r());
}
var __assign = function() {
  return (__assign = Object.assign || function(e) {
    for (var n, r = 1, t = arguments.length; r < t; r++)
      for (var o in n = arguments[r])
        Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
    return e;
  }).apply(this, arguments);
};
function __awaiter(e, i, s, u) {
  return new (s = s || Promise)(function(r, n) {
    function t(e2) {
      try {
        a(u.next(e2));
      } catch (e3) {
        n(e3);
      }
    }
    function o(e2) {
      try {
        a(u.throw(e2));
      } catch (e3) {
        n(e3);
      }
    }
    function a(e2) {
      var n2;
      e2.done ? r(e2.value) : ((n2 = e2.value) instanceof s ? n2 : new s(function(e3) {
        e3(n2);
      })).then(t, o);
    }
    a((u = u.apply(e, i || [])).next());
  });
}
function __generator(r, t) {
  var o, a, i, s = {label: 0, sent: function() {
    if (1 & i[0])
      throw i[1];
    return i[1];
  }, trys: [], ops: []}, e = {next: n(0), throw: n(1), return: n(2)};
  return typeof Symbol == "function" && (e[Symbol.iterator] = function() {
    return this;
  }), e;
  function n(n2) {
    return function(e2) {
      return function(n3) {
        if (o)
          throw new TypeError("Generator is already executing.");
        for (; s; )
          try {
            if (o = 1, a && (i = 2 & n3[0] ? a.return : n3[0] ? a.throw || ((i = a.return) && i.call(a), 0) : a.next) && !(i = i.call(a, n3[1])).done)
              return i;
            switch (a = 0, (n3 = i ? [2 & n3[0], i.value] : n3)[0]) {
              case 0:
              case 1:
                i = n3;
                break;
              case 4:
                return s.label++, {value: n3[1], done: false};
              case 5:
                s.label++, a = n3[1], n3 = [0];
                continue;
              case 7:
                n3 = s.ops.pop(), s.trys.pop();
                continue;
              default:
                if (!(i = 0 < (i = s.trys).length && i[i.length - 1]) && (n3[0] === 6 || n3[0] === 2)) {
                  s = 0;
                  continue;
                }
                if (n3[0] === 3 && (!i || n3[1] > i[0] && n3[1] < i[3])) {
                  s.label = n3[1];
                  break;
                }
                if (n3[0] === 6 && s.label < i[1]) {
                  s.label = i[1], i = n3;
                  break;
                }
                if (i && s.label < i[2]) {
                  s.label = i[2], s.ops.push(n3);
                  break;
                }
                i[2] && s.ops.pop(), s.trys.pop();
                continue;
            }
            n3 = t.call(r, s);
          } catch (e3) {
            n3 = [6, e3], a = 0;
          } finally {
            o = i = 0;
          }
        if (5 & n3[0])
          throw n3[1];
        return {value: n3[0] ? n3[1] : void 0, done: true};
      }([n2, e2]);
    };
  }
}
function __values(e) {
  var n = typeof Symbol == "function" && Symbol.iterator, r = n && e[n], t = 0;
  if (r)
    return r.call(e);
  if (e && typeof e.length == "number")
    return {next: function() {
      return {value: (e = e && t >= e.length ? void 0 : e) && e[t++], done: !e};
    }};
  throw new TypeError(n ? "Object is not iterable." : "Symbol.iterator is not defined.");
}
function __read(e, n) {
  var r = typeof Symbol == "function" && e[Symbol.iterator];
  if (!r)
    return e;
  var t, o, a = r.call(e), i = [];
  try {
    for (; (n === void 0 || 0 < n--) && !(t = a.next()).done; )
      i.push(t.value);
  } catch (e2) {
    o = {error: e2};
  } finally {
    try {
      t && !t.done && (r = a.return) && r.call(a);
    } finally {
      if (o)
        throw o.error;
    }
  }
  return i;
}
function __spreadArray(e, n) {
  for (var r = 0, t = n.length, o = e.length; r < t; r++, o++)
    e[o] = n[r];
  return e;
}
var util, PseudoPromise = function() {
  function n(e) {
    this.promise = e;
  }
  return n.prototype.then = function(e) {
    return new n(this.promise.then(e));
  }, n.all = function(e) {
    return new n(Promise.all(e.map(function(e2) {
      return e2 instanceof n ? e2.promise : e2;
    })));
  }, n;
}();
!function(u) {
  u.assertNever = function(e) {
    throw new Error();
  }, u.arrayToEnum = function(e) {
    var n, r, t = {};
    try {
      for (var o = __values(e), a = o.next(); !a.done; a = o.next()) {
        var i = a.value;
        t[i] = i;
      }
    } catch (e2) {
      n = {error: e2};
    } finally {
      try {
        a && !a.done && (r = o.return) && r.call(o);
      } finally {
        if (n)
          throw n.error;
      }
    }
    return t;
  }, u.getValidEnumValues = function(n) {
    var r, e, t = Object.keys(n).filter(function(e2) {
      return typeof n[n[e2]] != "number";
    }), o = {};
    try {
      for (var a = __values(t), i = a.next(); !i.done; i = a.next()) {
        var s = i.value;
        o[s] = n[s];
      }
    } catch (e2) {
      r = {error: e2};
    } finally {
      try {
        i && !i.done && (e = a.return) && e.call(a);
      } finally {
        if (r)
          throw r.error;
      }
    }
    return u.getValues(o);
  }, u.getValues = function(n) {
    return Object.keys(n).map(function(e) {
      return n[e];
    });
  }, u.objectValues = function(n) {
    return Object.keys(n).map(function(e) {
      return n[e];
    });
  }, u.find = function(e, n) {
    var r, t;
    try {
      for (var o = __values(e), a = o.next(); !a.done; a = o.next()) {
        var i = a.value;
        if (n(i))
          return i;
      }
    } catch (e2) {
      r = {error: e2};
    } finally {
      try {
        a && !a.done && (t = o.return) && t.call(o);
      } finally {
        if (r)
          throw r.error;
      }
    }
  };
}(util = util || {});
var errorUtil, ZodIssueCode = util.arrayToEnum(["invalid_type", "custom", "invalid_union", "invalid_enum_value", "unrecognized_keys", "invalid_arguments", "invalid_return_type", "invalid_date", "invalid_string", "too_small", "too_big", "invalid_intersection_types"]), quotelessJson = function(e) {
  return JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
}, ZodError = function(r) {
  function n(e) {
    var n2 = this.constructor, s = r.call(this) || this;
    s.issues = [], s.format = function() {
      var c = {_errors: []}, l = function(e2) {
        var n3, r2;
        try {
          for (var t = __values(e2.issues), o = t.next(); !o.done; o = t.next()) {
            var a = o.value;
            if (a.code === "invalid_union")
              a.unionErrors.map(l);
            else if (a.code === "invalid_return_type")
              l(a.returnTypeError);
            else if (a.code === "invalid_arguments")
              l(a.argumentsError);
            else if (a.path.length === 0)
              c._errors.push(a.message);
            else
              for (var i = c, s2 = 0; s2 < a.path.length; ) {
                var u, d = a.path[s2];
                s2 === a.path.length - 1 ? (i[d] = i[d] || {_errors: []}, i[d]._errors.push(a.message)) : typeof d == "string" ? i[d] = i[d] || {_errors: []} : typeof d == "number" && ((u = [])._errors = [], i[d] = i[d] || u), i = i[d], s2++;
              }
          }
        } catch (e3) {
          n3 = {error: e3};
        } finally {
          try {
            o && !o.done && (r2 = t.return) && r2.call(t);
          } finally {
            if (n3)
              throw n3.error;
          }
        }
      };
      return l(s), c;
    }, s.addIssue = function(e2) {
      s.issues = __spreadArray(__spreadArray([], __read(s.issues)), [e2]);
    }, s.addIssues = function(e2) {
      e2 === void 0 && (e2 = []), s.issues = __spreadArray(__spreadArray([], __read(s.issues)), __read(e2));
    }, s.flatten = function() {
      var n3, e2, r2 = {}, t = [];
      try {
        for (var o = __values(s.issues), a = o.next(); !a.done; a = o.next()) {
          var i = a.value;
          0 < i.path.length ? (r2[i.path[0]] = r2[i.path[0]] || [], r2[i.path[0]].push(i.message)) : t.push(i.message);
        }
      } catch (e3) {
        n3 = {error: e3};
      } finally {
        try {
          a && !a.done && (e2 = o.return) && e2.call(o);
        } finally {
          if (n3)
            throw n3.error;
        }
      }
      return {formErrors: t, fieldErrors: r2};
    };
    n2 = n2.prototype;
    return Object.setPrototypeOf(s, n2), s.issues = e, s;
  }
  return __extends(n, r), Object.defineProperty(n.prototype, "errors", {get: function() {
    return this.issues;
  }, enumerable: false, configurable: true}), n.prototype.toString = function() {
    return "ZodError: " + JSON.stringify(this.issues, null, 2);
  }, Object.defineProperty(n.prototype, "message", {get: function() {
    return JSON.stringify(this.issues, null, 2);
  }, enumerable: false, configurable: true}), Object.defineProperty(n.prototype, "isEmpty", {get: function() {
    return this.issues.length === 0;
  }, enumerable: false, configurable: true}), Object.defineProperty(n.prototype, "formErrors", {get: function() {
    return this.flatten();
  }, enumerable: false, configurable: true}), n.create = function(e) {
    return new n(e);
  }, n;
}(Error), defaultErrorMap = function(e, n) {
  var r;
  switch (e.code) {
    case ZodIssueCode.invalid_type:
      r = e.received === "undefined" ? "Required" : "Expected " + e.expected + ", received " + e.received;
      break;
    case ZodIssueCode.unrecognized_keys:
      r = "Unrecognized key(s) in object: " + e.keys.map(function(e2) {
        return "'" + e2 + "'";
      }).join(", ");
      break;
    case ZodIssueCode.invalid_union:
      r = "Invalid input";
      break;
    case ZodIssueCode.invalid_enum_value:
      r = "Invalid enum value. Expected " + e.options.map(function(e2) {
        return typeof e2 == "string" ? "'" + e2 + "'" : e2;
      }).join(" | ") + ", received " + (typeof n.data == "string" ? "'" + n.data + "'" : n.data);
      break;
    case ZodIssueCode.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case ZodIssueCode.invalid_return_type:
      r = "Invalid function return type";
      break;
    case ZodIssueCode.invalid_date:
      r = "Invalid date";
      break;
    case ZodIssueCode.invalid_string:
      r = e.validation !== "regex" ? "Invalid " + e.validation : "Invalid";
      break;
    case ZodIssueCode.too_small:
      r = e.type === "array" ? "Should have " + (e.inclusive ? "at least" : "more than") + " " + e.minimum + " items" : e.type === "string" ? "Should be " + (e.inclusive ? "at least" : "over") + " " + e.minimum + " characters" : e.type === "number" ? "Value should be greater than " + (e.inclusive ? "or equal to " : "") + e.minimum : "Invalid input";
      break;
    case ZodIssueCode.too_big:
      r = e.type === "array" ? "Should have " + (e.inclusive ? "at most" : "less than") + " " + e.maximum + " items" : e.type === "string" ? "Should be " + (e.inclusive ? "at most" : "under") + " " + e.maximum + " characters long" : e.type === "number" ? "Value should be less than " + (e.inclusive ? "or equal to " : "") + e.maximum : "Invalid input";
      break;
    case ZodIssueCode.custom:
      r = "Invalid input";
      break;
    case ZodIssueCode.invalid_intersection_types:
      r = "Intersections only support objects";
      break;
    default:
      r = n.defaultError, util.assertNever(e);
  }
  return {message: r};
}, overrideErrorMap = defaultErrorMap, setErrorMap = function(e) {
  overrideErrorMap = e;
}, ZodParsedType = util.arrayToEnum(["string", "nan", "number", "integer", "float", "boolean", "date", "bigint", "symbol", "function", "undefined", "null", "array", "object", "unknown", "promise", "void", "never", "map", "set"]), getParsedType = function(e) {
  switch (typeof e) {
    case "undefined":
      return ZodParsedType.undefined;
    case "string":
      return ZodParsedType.string;
    case "number":
      return Number.isNaN(e) ? ZodParsedType.nan : ZodParsedType.number;
    case "boolean":
      return ZodParsedType.boolean;
    case "function":
      return ZodParsedType.function;
    case "bigint":
      return ZodParsedType.bigint;
    case "object":
      return Array.isArray(e) ? ZodParsedType.array : e === null ? ZodParsedType.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? ZodParsedType.promise : e instanceof Map ? ZodParsedType.map : e instanceof Set ? ZodParsedType.set : e instanceof Date ? ZodParsedType.date : ZodParsedType.object;
    default:
      return ZodParsedType.unknown;
  }
}, makeIssue = function(e, n, r, t) {
  var o = __spreadArray(__spreadArray([], __read(n)), __read(t.path || [])), a = __assign(__assign({}, t), {path: o}), n = defaultErrorMap(a, {data: e, defaultError: "Invalid input"});
  return __assign(__assign({}, t), {path: o, message: t.message || r(a, {data: e, defaultError: n.message}).message});
}, EMPTY_PATH = null, pathToArray = function(e) {
  if (e === null)
    return [];
  for (var n = new Array(e.count); e !== null; )
    n[e.count - 1] = e.component, e = e.parent;
  return n;
}, pathFromArray = function(e) {
  for (var n = null, r = 0; r < e.length; r++)
    n = {parent: n, component: e[r], count: r + 1};
  return n;
}, ParseContext = function() {
  function n(e, n2, r) {
    this.path = e, this.issues = n2, this.params = r;
  }
  return n.prototype.stepInto = function(e) {
    return new n(this.path === null ? {parent: null, count: 1, component: e} : {parent: this.path, count: this.path.count + 1, component: e}, this.issues, this.params);
  }, n.prototype.addIssue = function(e, n2) {
    n2 = makeIssue(e, pathToArray(this.path), this.params.errorMap, n2);
    this.issues.push(n2);
  }, n;
}(), createRootContext$1 = function(e) {
  var n;
  return new ParseContext(EMPTY_PATH, [], {async: (n = e.async) !== null && n !== void 0 && n, errorMap: e.errorMap || overrideErrorMap});
}, INVALID = Object.freeze({valid: false}), OK = function(e) {
  return {valid: true, value: e};
}, ASYNC = function(e) {
  return new PseudoPromise(e);
}, isInvalid = function(e) {
  return e === INVALID;
}, isOk = function(e) {
  return e.valid === true;
}, isAsync = function(e) {
  return e instanceof PseudoPromise;
};
!function(e) {
  e.errToObj = function(e2) {
    return typeof e2 == "string" ? {message: e2} : e2 || {};
  }, e.toString = function(e2) {
    return typeof e2 == "string" ? e2 : e2 == null ? void 0 : e2.message;
  };
}(errorUtil = errorUtil || {});
var objectUtil, createTasks = function(e) {
  return e.params.async ? [] : null;
}, createRootContext = function(e) {
  var n;
  return new ParseContext(pathFromArray(e.path || []), [], {async: (n = e.async) !== null && n !== void 0 && n, errorMap: e.errorMap || overrideErrorMap});
}, handleResult = function(e, n, r) {
  return isOk(n) ? {success: true, data: n.value} : (r != null && r.addIssues(e.issues), {success: false, error: new ZodError(e.issues)});
}, ZodType = function() {
  function e(e2) {
    var i = this;
    this.parse = function(e3, n) {
      n = i.safeParse(e3, n);
      if (n.success)
        return n.data;
      throw n.error;
    }, this.safeParse = function(e3, n) {
      var r = createRootContext(__assign(__assign({}, n), {async: false})), e3 = i._parseSync(r, e3, getParsedType(e3));
      return handleResult(r, e3, n == null ? void 0 : n.parentError);
    }, this.parseAsync = function(r, t) {
      return __awaiter(i, void 0, void 0, function() {
        var n;
        return __generator(this, function(e3) {
          switch (e3.label) {
            case 0:
              return [4, this.safeParseAsync(r, t)];
            case 1:
              if ((n = e3.sent()).success)
                return [2, n.data];
              throw n.error;
          }
        });
      });
    }, this.safeParseAsync = function(t, o) {
      return __awaiter(i, void 0, void 0, function() {
        var n, r;
        return __generator(this, function(e3) {
          switch (e3.label) {
            case 0:
              return n = createRootContext(__assign(__assign({}, o), {async: true})), r = this._parse(n, t, getParsedType(t)), [4, isAsync(r) ? r.promise : Promise.resolve(r)];
            case 1:
              return r = e3.sent(), [2, handleResult(n, r, o == null ? void 0 : o.parentError)];
          }
        });
      });
    }, this.spa = this.safeParseAsync, this.refine = function(o, a) {
      return i._refinement(function(n, r) {
        function t() {
          return r.addIssue(__assign({code: ZodIssueCode.custom}, (e4 = n, typeof a == "string" || a === void 0 ? {message: a} : typeof a == "function" ? a(e4) : a)));
          var e4;
        }
        var e3 = o(n);
        return e3 instanceof Promise ? e3.then(function(e4) {
          return !!e4 || (t(), false);
        }) : !!e3 || (t(), false);
      });
    }, this.refinement = function(r, t) {
      return i._refinement(function(e3, n) {
        return !!r(e3) || (n.addIssue(typeof t == "function" ? t(e3, n) : t), false);
      });
    }, this.superRefine = this._refinement, this.optional = function() {
      return ZodOptional.create(i);
    }, this.nullable = function() {
      return ZodNullable.create(i);
    }, this.nullish = function() {
      return i.optional().nullable();
    }, this.array = function() {
      return ZodArray.create(i);
    }, this.isOptional = function() {
      return i.safeParse(void 0).success;
    }, this.isNullable = function() {
      return i.safeParse(null).success;
    }, this._def = e2, this.transform = this.transform.bind(this), this.default = this.default.bind(this);
  }
  return e.prototype._parseSync = function(e2, n, r) {
    r = this._parse(e2, n, r);
    if (isAsync(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }, e.prototype._refinement = function(e2) {
    e2 = new ZodEffects(this instanceof ZodEffects ? __assign(__assign({}, this._def), {effects: __spreadArray(__spreadArray([], __read(this._def.effects || [])), [{type: "refinement", refinement: e2}])}) : {schema: this, typeName: ZodFirstPartyTypeKind.ZodEffects, effects: [{type: "refinement", refinement: e2}]});
    return e2;
  }, e.prototype.or = function(e2) {
    return ZodUnion.create([this, e2]);
  }, e.prototype.and = function(e2) {
    return ZodIntersection.create(this, e2);
  }, e.prototype.transform = function(e2) {
    return new ZodEffects({schema: this, typeName: ZodFirstPartyTypeKind.ZodEffects, effects: [{type: "transform", transform: e2}]});
  }, e.prototype.default = function(e2) {
    return new ZodDefault({innerType: this, defaultValue: typeof e2 == "function" ? e2 : function() {
      return e2;
    }, typeName: ZodFirstPartyTypeKind.ZodDefault});
  }, e;
}(), uuidRegex = /^([a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}|00000000-0000-0000-0000-000000000000)$/i, emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, ZodString = function(e) {
  function r() {
    var t = e !== null && e.apply(this, arguments) || this;
    return t._regex = function(n, e2, r2) {
      return t.refinement(function(e3) {
        return n.test(e3);
      }, __assign({validation: e2, code: ZodIssueCode.invalid_string}, errorUtil.errToObj(r2)));
    }, t.email = function(e2) {
      return new r(__assign(__assign({}, t._def), {checks: __spreadArray(__spreadArray([], __read(t._def.checks)), [__assign({kind: "email"}, errorUtil.errToObj(e2))])}));
    }, t.url = function(e2) {
      return new r(__assign(__assign({}, t._def), {checks: __spreadArray(__spreadArray([], __read(t._def.checks)), [__assign({kind: "url"}, errorUtil.errToObj(e2))])}));
    }, t.uuid = function(e2) {
      return new r(__assign(__assign({}, t._def), {checks: __spreadArray(__spreadArray([], __read(t._def.checks)), [__assign({kind: "uuid"}, errorUtil.errToObj(e2))])}));
    }, t.regex = function(e2, n) {
      return new r(__assign(__assign({}, t._def), {checks: __spreadArray(__spreadArray([], __read(t._def.checks)), [__assign({kind: "regex", regex: e2}, errorUtil.errToObj(n))])}));
    }, t.min = function(e2, n) {
      return new r(__assign(__assign({}, t._def), {checks: __spreadArray(__spreadArray([], __read(t._def.checks)), [__assign({kind: "min", value: e2}, errorUtil.errToObj(n))])}));
    }, t.max = function(e2, n) {
      return new r(__assign(__assign({}, t._def), {checks: __spreadArray(__spreadArray([], __read(t._def.checks)), [__assign({kind: "max", value: e2}, errorUtil.errToObj(n))])}));
    }, t.nonempty = function(e2) {
      return t.min(1, errorUtil.errToObj(e2));
    }, t;
  }
  return __extends(r, e), r.prototype._parse = function(n, r2, e2) {
    var t, o;
    if (e2 !== ZodParsedType.string)
      return n.addIssue(r2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.string, received: e2}), INVALID;
    var a = false;
    try {
      for (var i = __values(this._def.checks), s = i.next(); !s.done; s = i.next()) {
        var u = s.value;
        if (u.kind === "min")
          r2.length < u.value && (a = true, n.addIssue(r2, {code: ZodIssueCode.too_small, minimum: u.value, type: "string", inclusive: true, message: u.message}));
        else if (u.kind === "max")
          r2.length > u.value && (a = true, n.addIssue(r2, {code: ZodIssueCode.too_big, maximum: u.value, type: "string", inclusive: true, message: u.message}));
        else if (u.kind === "email")
          emailRegex.test(r2) || (a = true, n.addIssue(r2, {validation: "email", code: ZodIssueCode.invalid_string, message: u.message}));
        else if (u.kind === "uuid")
          uuidRegex.test(r2) || (a = true, n.addIssue(r2, {validation: "uuid", code: ZodIssueCode.invalid_string, message: u.message}));
        else if (u.kind === "url")
          try {
            new URL(r2);
          } catch (e3) {
            a = true, n.addIssue(r2, {validation: "url", code: ZodIssueCode.invalid_string, message: u.message});
          }
        else
          u.kind === "regex" && (u.regex.test(r2) || (a = true, n.addIssue(r2, {validation: "regex", code: ZodIssueCode.invalid_string, message: u.message})));
      }
    } catch (e3) {
      t = {error: e3};
    } finally {
      try {
        s && !s.done && (o = i.return) && o.call(i);
      } finally {
        if (t)
          throw t.error;
      }
    }
    return a ? INVALID : OK(r2);
  }, r.prototype.length = function(e2, n) {
    return this.min(e2, n).max(e2, n);
  }, Object.defineProperty(r.prototype, "isEmail", {get: function() {
    return !!this._def.checks.find(function(e2) {
      return e2.kind === "email";
    });
  }, enumerable: false, configurable: true}), Object.defineProperty(r.prototype, "isURL", {get: function() {
    return !!this._def.checks.find(function(e2) {
      return e2.kind === "url";
    });
  }, enumerable: false, configurable: true}), Object.defineProperty(r.prototype, "isUUID", {get: function() {
    return !!this._def.checks.find(function(e2) {
      return e2.kind === "uuid";
    });
  }, enumerable: false, configurable: true}), Object.defineProperty(r.prototype, "minLength", {get: function() {
    var n = -1 / 0;
    return this._def.checks.map(function(e2) {
      e2.kind === "min" && (n === null || e2.value > n) && (n = e2.value);
    }), n;
  }, enumerable: false, configurable: true}), Object.defineProperty(r.prototype, "maxLength", {get: function() {
    var n = null;
    return this._def.checks.map(function(e2) {
      e2.kind === "min" && (n === null || e2.value < n) && (n = e2.value);
    }), n;
  }, enumerable: false, configurable: true}), r.create = function() {
    return new r({checks: [], typeName: ZodFirstPartyTypeKind.ZodString});
  }, r;
}(ZodType), ZodNumber = function(e) {
  function t() {
    var r = e !== null && e.apply(this, arguments) || this;
    return r.min = function(e2, n) {
      return new t(__assign(__assign({}, r._def), {checks: __spreadArray(__spreadArray([], __read(r._def.checks)), [{kind: "min", value: e2, inclusive: true, message: errorUtil.toString(n)}])}));
    }, r.max = function(e2, n) {
      return new t(__assign(__assign({}, r._def), {checks: __spreadArray(__spreadArray([], __read(r._def.checks)), [{kind: "max", value: e2, inclusive: true, message: errorUtil.toString(n)}])}));
    }, r.int = function(e2) {
      return new t(__assign(__assign({}, r._def), {checks: __spreadArray(__spreadArray([], __read(r._def.checks)), [{kind: "int", message: errorUtil.toString(e2)}])}));
    }, r.positive = function(e2) {
      return new t(__assign(__assign({}, r._def), {checks: __spreadArray(__spreadArray([], __read(r._def.checks)), [{kind: "min", value: 0, inclusive: false, message: errorUtil.toString(e2)}])}));
    }, r.negative = function(e2) {
      return new t(__assign(__assign({}, r._def), {checks: __spreadArray(__spreadArray([], __read(r._def.checks)), [{kind: "max", value: 0, inclusive: false, message: errorUtil.toString(e2)}])}));
    }, r.nonpositive = function(e2) {
      return new t(__assign(__assign({}, r._def), {checks: __spreadArray(__spreadArray([], __read(r._def.checks)), [{kind: "max", value: 0, inclusive: true, message: errorUtil.toString(e2)}])}));
    }, r.nonnegative = function(e2) {
      return new t(__assign(__assign({}, r._def), {checks: __spreadArray(__spreadArray([], __read(r._def.checks)), [{kind: "min", value: 0, inclusive: true, message: errorUtil.toString(e2)}])}));
    }, r;
  }
  return __extends(t, e), t.prototype._parse = function(e2, n, r) {
    var t2, o;
    if (r !== ZodParsedType.number)
      return e2.addIssue(n, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.number, received: r}), INVALID;
    var a = false;
    try {
      for (var i = __values(this._def.checks), s = i.next(); !s.done; s = i.next()) {
        var u = s.value;
        u.kind === "int" ? Number.isInteger(n) || (a = true, e2.addIssue(n, {code: ZodIssueCode.invalid_type, expected: "integer", received: "float", message: u.message})) : u.kind === "min" ? (u.inclusive ? n < u.value : n <= u.value) && (a = true, e2.addIssue(n, {code: ZodIssueCode.too_small, minimum: u.value, type: "number", inclusive: u.inclusive, message: u.message})) : u.kind === "max" && (u.inclusive ? n > u.value : n >= u.value) && (a = true, e2.addIssue(n, {code: ZodIssueCode.too_big, maximum: u.value, type: "number", inclusive: u.inclusive, message: u.message}));
      }
    } catch (e3) {
      t2 = {error: e3};
    } finally {
      try {
        s && !s.done && (o = i.return) && o.call(i);
      } finally {
        if (t2)
          throw t2.error;
      }
    }
    return a ? INVALID : OK(n);
  }, Object.defineProperty(t.prototype, "minValue", {get: function() {
    var n, e2, r = null;
    try {
      for (var t2 = __values(this._def.checks), o = t2.next(); !o.done; o = t2.next()) {
        var a = o.value;
        a.kind === "min" && (r === null || a.value > r) && (r = a.value);
      }
    } catch (e3) {
      n = {error: e3};
    } finally {
      try {
        o && !o.done && (e2 = t2.return) && e2.call(t2);
      } finally {
        if (n)
          throw n.error;
      }
    }
    return r;
  }, enumerable: false, configurable: true}), Object.defineProperty(t.prototype, "maxValue", {get: function() {
    var n, e2, r = null;
    try {
      for (var t2 = __values(this._def.checks), o = t2.next(); !o.done; o = t2.next()) {
        var a = o.value;
        a.kind === "max" && (r === null || a.value < r) && (r = a.value);
      }
    } catch (e3) {
      n = {error: e3};
    } finally {
      try {
        o && !o.done && (e2 = t2.return) && e2.call(t2);
      } finally {
        if (n)
          throw n.error;
      }
    }
    return r;
  }, enumerable: false, configurable: true}), Object.defineProperty(t.prototype, "isInt", {get: function() {
    return !!this._def.checks.find(function(e2) {
      return e2.kind === "int";
    });
  }, enumerable: false, configurable: true}), t.create = function() {
    return new t({checks: [], typeName: ZodFirstPartyTypeKind.ZodNumber});
  }, t;
}(ZodType), ZodBigInt = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r !== ZodParsedType.bigint ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.bigint, received: r}), INVALID) : OK(n2);
  }, n.create = function() {
    return new n({typeName: ZodFirstPartyTypeKind.ZodBigInt});
  }, n;
}(ZodType), ZodBoolean = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r !== ZodParsedType.boolean ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.boolean, received: r}), INVALID) : OK(n2);
  }, n.create = function() {
    return new n({typeName: ZodFirstPartyTypeKind.ZodBoolean});
  }, n;
}(ZodType), ZodDate = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r !== ZodParsedType.date ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.date, received: r}), INVALID) : isNaN(n2.getTime()) ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_date}), INVALID) : OK(new Date(n2.getTime()));
  }, n.create = function() {
    return new n({typeName: ZodFirstPartyTypeKind.ZodDate});
  }, n;
}(ZodType), ZodUndefined = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r !== ZodParsedType.undefined ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.undefined, received: r}), INVALID) : OK(n2);
  }, n.create = function() {
    return new n({typeName: ZodFirstPartyTypeKind.ZodUndefined});
  }, n;
}(ZodType), ZodNull = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r !== ZodParsedType.null ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.null, received: r}), INVALID) : OK(n2);
  }, n.create = function() {
    return new n({typeName: ZodFirstPartyTypeKind.ZodNull});
  }, n;
}(ZodType), ZodAny = function(n) {
  function e() {
    var e2 = n !== null && n.apply(this, arguments) || this;
    return e2._any = true, e2;
  }
  return __extends(e, n), e.prototype._parse = function(e2, n2, r) {
    return OK(n2);
  }, e.create = function() {
    return new e({typeName: ZodFirstPartyTypeKind.ZodAny});
  }, e;
}(ZodType), ZodUnknown = function(n) {
  function e() {
    var e2 = n !== null && n.apply(this, arguments) || this;
    return e2._unknown = true, e2;
  }
  return __extends(e, n), e.prototype._parse = function(e2, n2, r) {
    return OK(n2);
  }, e.create = function() {
    return new e({typeName: ZodFirstPartyTypeKind.ZodUnknown});
  }, e;
}(ZodType), ZodNever = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.never, received: r}), INVALID;
  }, n.create = function() {
    return new n({typeName: ZodFirstPartyTypeKind.ZodNever});
  }, n;
}(ZodType), ZodVoid = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r !== ZodParsedType.undefined && r !== ZodParsedType.null ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.void, received: r}), INVALID) : OK(n2);
  }, n.create = function() {
    return new n({typeName: ZodFirstPartyTypeKind.ZodVoid});
  }, n;
}(ZodType), parseArray = function(r, e, n, t, o) {
  if (n !== ZodParsedType.array)
    return r.addIssue(e, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.array, received: n}), INVALID;
  var a = false;
  if (t.minLength !== null && e.length < t.minLength.value && (a = true, r.addIssue(e, {code: ZodIssueCode.too_small, minimum: t.minLength.value, type: "array", inclusive: true, message: t.minLength.message})), t.maxLength !== null && e.length > t.maxLength.value && (a = true, r.addIssue(e, {code: ZodIssueCode.too_big, maximum: t.maxLength.value, type: "array", inclusive: true, message: t.maxLength.message})), o && e.length < 1 && (a = true, r.addIssue(e, {code: ZodIssueCode.too_small, minimum: 1, type: "array", inclusive: true})), a)
    return INVALID;
  var i = createTasks(r), s = new Array(e.length), u = t.type, d = function(n2, e2) {
    isOk(e2) ? s[n2] = e2.value : isInvalid(e2) ? a = true : i != null && i.push(e2.promise.then(function(e3) {
      return d(n2, e3);
    }));
  };
  return e.forEach(function(e2, n2) {
    d(n2, u._parse(r.stepInto(n2), e2, getParsedType(e2)));
  }), i !== null && 0 < i.length ? ASYNC(Promise.all(i).then(function() {
    return a ? INVALID : OK(s);
  })) : a ? INVALID : OK(s);
}, ZodArray = function(e) {
  function t() {
    var r = e !== null && e.apply(this, arguments) || this;
    return r.min = function(e2, n) {
      return new t(__assign(__assign({}, r._def), {minLength: {value: e2, message: errorUtil.toString(n)}}));
    }, r.max = function(e2, n) {
      return new t(__assign(__assign({}, r._def), {maxLength: {value: e2, message: errorUtil.toString(n)}}));
    }, r.length = function(e2, n) {
      return r.min(e2, n).max(e2, n);
    }, r.nonempty = function() {
      return new ZodNonEmptyArray(__assign({}, r._def));
    }, r;
  }
  return __extends(t, e), t.prototype._parse = function(e2, n, r) {
    return parseArray(e2, n, r, this._def, false);
  }, Object.defineProperty(t.prototype, "element", {get: function() {
    return this._def.type;
  }, enumerable: false, configurable: true}), t.create = function(e2) {
    return new t({type: e2, minLength: null, maxLength: null, typeName: ZodFirstPartyTypeKind.ZodArray});
  }, t;
}(ZodType), ZodNonEmptyArray = function(e) {
  function t() {
    var r = e !== null && e.apply(this, arguments) || this;
    return r.min = function(e2, n) {
      return new t(__assign(__assign({}, r._def), {minLength: {value: e2, message: errorUtil.toString(n)}}));
    }, r.max = function(e2, n) {
      return new t(__assign(__assign({}, r._def), {maxLength: {value: e2, message: errorUtil.toString(n)}}));
    }, r.length = function(e2, n) {
      return r.min(e2, n).max(e2, n);
    }, r;
  }
  return __extends(t, e), t.prototype._parse = function(e2, n, r) {
    return parseArray(e2, n, r, this._def, true);
  }, t.create = function(e2) {
    return new t({type: e2, minLength: null, maxLength: null, typeName: ZodFirstPartyTypeKind.ZodArray});
  }, t;
}(ZodType);
!function(e) {
  e.mergeShapes = function(e2, n) {
    return __assign(__assign({}, e2), n);
  }, e.intersectShapes = function(e2, n) {
    var r, t, o = Object.keys(e2), a = Object.keys(n), o = o.filter(function(e3) {
      return a.indexOf(e3) !== -1;
    }), i = {};
    try {
      for (var s = __values(o), u = s.next(); !u.done; u = s.next()) {
        var d = u.value;
        i[d] = ZodIntersection.create(e2[d], n[d]);
      }
    } catch (e3) {
      r = {error: e3};
    } finally {
      try {
        u && !u.done && (t = s.return) && t.call(s);
      } finally {
        if (r)
          throw r.error;
      }
    }
    return __assign(__assign(__assign({}, e2), n), i);
  };
}(objectUtil = objectUtil || {});
var mergeObjects = function(r) {
  return function(e) {
    var n = objectUtil.mergeShapes(r._def.shape(), e._def.shape());
    return new ZodObject({unknownKeys: r._def.unknownKeys, catchall: r._def.catchall, shape: function() {
      return n;
    }, typeName: ZodFirstPartyTypeKind.ZodObject});
  };
}, AugmentFactory = function(n) {
  return function(e) {
    return new ZodObject(__assign(__assign({}, n), {shape: function() {
      return __assign(__assign({}, n.shape()), e);
    }}));
  };
}, ZodObject = function(e) {
  function o() {
    var t = e !== null && e.apply(this, arguments) || this;
    return t._cached = null, t.strict = function() {
      return new o(__assign(__assign({}, t._def), {unknownKeys: "strict"}));
    }, t.strip = function() {
      return new o(__assign(__assign({}, t._def), {unknownKeys: "strip"}));
    }, t.passthrough = function() {
      return new o(__assign(__assign({}, t._def), {unknownKeys: "passthrough"}));
    }, t.nonstrict = t.passthrough, t.augment = AugmentFactory(t._def), t.extend = AugmentFactory(t._def), t.setKey = function(e2, n) {
      var r;
      return t.augment(((r = {})[e2] = n, r));
    }, t.merge = function(e2) {
      var n = objectUtil.mergeShapes(t._def.shape(), e2._def.shape());
      return new o({unknownKeys: e2._def.unknownKeys, catchall: e2._def.catchall, shape: function() {
        return n;
      }, typeName: ZodFirstPartyTypeKind.ZodObject});
    }, t.catchall = function(e2) {
      return new o(__assign(__assign({}, t._def), {catchall: e2}));
    }, t.pick = function(e2) {
      var n = {};
      return Object.keys(e2).map(function(e3) {
        n[e3] = t.shape[e3];
      }), new o(__assign(__assign({}, t._def), {shape: function() {
        return n;
      }}));
    }, t.omit = function(n) {
      var r = {};
      return Object.keys(t.shape).map(function(e2) {
        Object.keys(n).indexOf(e2) === -1 && (r[e2] = t.shape[e2]);
      }), new o(__assign(__assign({}, t._def), {shape: function() {
        return r;
      }}));
    }, t.partial = function() {
      var e2, n = {};
      for (e2 in t.shape) {
        var r = t.shape[e2];
        n[e2] = r.isOptional() ? r : r.optional();
      }
      return new o(__assign(__assign({}, t._def), {shape: function() {
        return n;
      }}));
    }, t.deepPartial = function() {
      var e2, n = {};
      for (e2 in t.shape) {
        var r = t.shape[e2];
        n[e2] = r instanceof o ? r.isOptional() ? r : r.deepPartial().optional() : r.isOptional() ? r : r.optional();
      }
      return new o(__assign(__assign({}, t._def), {shape: function() {
        return n;
      }}));
    }, t.required = function() {
      var e2, n = {};
      for (e2 in t.shape) {
        for (var r = t.shape[e2]; r instanceof ZodOptional; )
          r = r._def.innerType;
        n[e2] = r;
      }
      return new o(__assign(__assign({}, t._def), {shape: function() {
        return n;
      }}));
    }, t;
  }
  return __extends(o, e), o.prototype._getCached = function() {
    if (this._cached !== null)
      return this._cached;
    var e2 = this._def.shape(), n = Object.keys(e2);
    return this._cached = {shape: e2, keys: n};
  }, o.prototype._parse = function(e2, t, n) {
    var r, o2, a, i, s;
    if (n !== ZodParsedType.object)
      return e2.addIssue(t, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.object, received: n}), INVALID;
    var n = this._getCached(), u = n.shape, n = n.keys, d = false, c = createTasks(e2), l = {}, p = function(n2, e3) {
      var r2;
      isOk(e3) ? ((r2 = e3.value) !== void 0 || n2 in t) && (l[n2] = r2) : isInvalid(e3) ? d = true : c != null && c.push(e3.promise.then(function(e4) {
        return p(n2, e4);
      }));
    };
    try {
      for (var f = __values(n), y = f.next(); !y.done; y = f.next()) {
        var _ = y.value, h = u[_], m = t[_];
        p(_, h._parse(e2.stepInto(_), m, getParsedType(m)));
      }
    } catch (e3) {
      v = {error: e3};
    } finally {
      try {
        y && !y.done && (r = f.return) && r.call(f);
      } finally {
        if (v)
          throw v.error;
      }
    }
    if (this._def.catchall instanceof ZodNever) {
      var v = this._def.unknownKeys;
      if (v === "passthrough") {
        var Z = Object.keys(t).filter(function(e3) {
          return !(e3 in u);
        });
        try {
          for (var g = __values(Z), T = g.next(); !T.done; T = g.next()) {
            _ = T.value;
            l[_] = t[_];
          }
        } catch (e3) {
          o2 = {error: e3};
        } finally {
          try {
            T && !T.done && (a = g.return) && a.call(g);
          } finally {
            if (o2)
              throw o2.error;
          }
        }
      } else if (v === "strict")
        0 < (Z = Object.keys(t).filter(function(e3) {
          return !(e3 in u);
        })).length && (d = true, e2.addIssue(t, {code: ZodIssueCode.unrecognized_keys, keys: Z}));
      else if (v !== "strip")
        throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      var I = this._def.catchall, Z = Object.keys(t).filter(function(e3) {
        return !(e3 in u);
      });
      try {
        for (var b = __values(Z), P = b.next(); !P.done; P = b.next()) {
          _ = P.value, m = t[_];
          p(_, I._parse(e2.stepInto(_), m, getParsedType(m)));
        }
      } catch (e3) {
        i = {error: e3};
      } finally {
        try {
          P && !P.done && (s = b.return) && s.call(b);
        } finally {
          if (i)
            throw i.error;
        }
      }
    }
    return c !== null && 0 < c.length ? ASYNC(Promise.all(c).then(function() {
      return d ? INVALID : OK(l);
    })) : d ? INVALID : OK(l);
  }, Object.defineProperty(o.prototype, "shape", {get: function() {
    return this._def.shape();
  }, enumerable: false, configurable: true}), o.create = function(e2) {
    return new o({shape: function() {
      return e2;
    }, unknownKeys: "strip", catchall: ZodNever.create(), typeName: ZodFirstPartyTypeKind.ZodObject});
  }, o.strictCreate = function(e2) {
    return new o({shape: function() {
      return e2;
    }, unknownKeys: "strict", catchall: ZodNever.create(), typeName: ZodFirstPartyTypeKind.ZodObject});
  }, o.lazycreate = function(e2) {
    return new o({shape: e2, unknownKeys: "strip", catchall: ZodNever.create(), typeName: ZodFirstPartyTypeKind.ZodObject});
  }, o;
}(ZodType), ZodUnion = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(r, t, o) {
    function i(e3) {
      var n3 = e3.map(function(e4) {
        return new ZodError(e4);
      });
      return (e3 = n3.filter(function(e4) {
        return e4.issues[0].code !== "invalid_type";
      })).length === 1 ? e3[0].issues.forEach(function(e4) {
        return r.issues.push(e4);
      }) : r.addIssue(t, {code: ZodIssueCode.invalid_union, unionErrors: n3}), INVALID;
    }
    var n2, e2, a = this._def.options;
    if (r.params.async) {
      var s = a.map(function() {
        return new ParseContext(r.path, [], r.params);
      });
      return PseudoPromise.all(a.map(function(e3, n3) {
        return e3._parse(s[n3], t, o);
      })).then(function(e3) {
        var n3, r2;
        try {
          for (var t2 = __values(e3), o2 = t2.next(); !o2.done; o2 = t2.next()) {
            var a2 = o2.value;
            if (isOk(a2))
              return a2;
          }
        } catch (e4) {
          n3 = {error: e4};
        } finally {
          try {
            o2 && !o2.done && (r2 = t2.return) && r2.call(t2);
          } finally {
            if (n3)
              throw n3.error;
          }
        }
        return i(s.map(function(e4) {
          return e4.issues;
        }));
      });
    }
    var u = [];
    try {
      for (var d = __values(a), c = d.next(); !c.done; c = d.next()) {
        var l = c.value, p = new ParseContext(r.path, [], r.params), f = l._parseSync(p, t, o);
        if (!isInvalid(f))
          return f;
        u.push(p.issues);
      }
    } catch (e3) {
      n2 = {error: e3};
    } finally {
      try {
        c && !c.done && (e2 = d.return) && e2.call(d);
      } finally {
        if (n2)
          throw n2.error;
      }
    }
    return i(u);
  }, Object.defineProperty(n.prototype, "options", {get: function() {
    return this._def.options;
  }, enumerable: false, configurable: true}), n.create = function(e2) {
    return new n({options: e2, typeName: ZodFirstPartyTypeKind.ZodUnion});
  }, n;
}(ZodType);
function mergeValues(e, n) {
  var r, t, o = getParsedType(e), a = getParsedType(n);
  if (e === n)
    return {valid: true, data: e};
  if (o !== ZodParsedType.object || a !== ZodParsedType.object)
    return {valid: false};
  var i = new Set(Object.keys(n)), a = Object.keys(e).filter(function(e2) {
    return i.has(e2);
  }), s = __assign(__assign({}, e), n);
  try {
    for (var u = __values(a), d = u.next(); !d.done; d = u.next()) {
      var c = d.value, l = mergeValues(e[c], n[c]);
      if (!l.valid)
        return {valid: false};
      s[c] = l.data;
    }
  } catch (e2) {
    r = {error: e2};
  } finally {
    try {
      d && !d.done && (t = u.return) && t.call(u);
    } finally {
      if (r)
        throw r.error;
    }
  }
  return {valid: true, data: s};
}
var ZodFirstPartyTypeKind, ZodIntersection = function(e) {
  function r() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(r, e), r.prototype._parse = function(r2, t, e2) {
    function o(e3, n) {
      return isInvalid(e3) || isInvalid(n) ? INVALID : (n = mergeValues(e3.value, n.value)).valid ? OK(n.data) : (r2.addIssue(t, {code: ZodIssueCode.invalid_intersection_types}), INVALID);
    }
    return r2.params.async ? PseudoPromise.all([this._def.left._parse(r2, t, e2), this._def.right._parse(r2, t, e2)]).then(function(e3) {
      var n = __read(e3, 2), e3 = n[0], n = n[1];
      return o(e3, n);
    }) : o(this._def.left._parseSync(r2, t, e2), this._def.right._parseSync(r2, t, e2));
  }, r.create = function(e2, n) {
    return new r({left: e2, right: n, typeName: ZodFirstPartyTypeKind.ZodIntersection});
  }, r;
}(ZodType), ZodTuple = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(r, t, e2) {
    if (e2 !== ZodParsedType.array)
      return r.addIssue(t, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.array, received: e2}), INVALID;
    if (t.length > this._def.items.length)
      return r.addIssue(t, {code: ZodIssueCode.too_big, maximum: this._def.items.length, inclusive: true, type: "array"}), INVALID;
    if (t.length < this._def.items.length)
      return r.addIssue(t, {code: ZodIssueCode.too_small, minimum: this._def.items.length, inclusive: true, type: "array"}), INVALID;
    var o = createTasks(r), e2 = this._def.items, a = new Array(e2.length), i = false, s = function(n2, e3) {
      isOk(e3) ? a[n2] = e3.value : isInvalid(e3) ? i = true : o != null && o.push(e3.promise.then(function(e4) {
        return s(n2, e4);
      }));
    };
    return e2.forEach(function(e3, n2) {
      s(n2, e3._parse(r.stepInto(n2), t[n2], getParsedType(t[n2])));
    }), o !== null && 0 < o.length ? ASYNC(Promise.all(o).then(function() {
      return i ? INVALID : OK(a);
    })) : i ? INVALID : OK(a);
  }, Object.defineProperty(n.prototype, "items", {get: function() {
    return this._def.items;
  }, enumerable: false, configurable: true}), n.create = function(e2) {
    return new n({items: e2, typeName: ZodFirstPartyTypeKind.ZodTuple});
  }, n;
}(ZodType), ZodRecord = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    if (r !== ZodParsedType.object)
      return e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.object, received: r}), INVALID;
    var t, o = createTasks(e2), a = this._def.valueType, i = {}, s = false, u = function(n3, e3) {
      isOk(e3) ? i[n3] = e3.value : isInvalid(e3) ? s = true : o != null && o.push(e3.promise.then(function(e4) {
        return u(n3, e4);
      }));
    };
    for (t in n2)
      u(t, a._parse(e2.stepInto(t), n2[t], getParsedType(n2[t])));
    return o !== null && 0 < o.length ? ASYNC(Promise.all(o).then(function() {
      return s ? INVALID : OK(i);
    })) : s ? INVALID : OK(i);
  }, n.create = function(e2) {
    return new n({valueType: e2, typeName: ZodFirstPartyTypeKind.ZodRecord});
  }, n;
}(ZodType), ZodMap = function(e) {
  function r() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(r, e), r.prototype._parse = function(t, e2, n) {
    if (n !== ZodParsedType.map)
      return t.addIssue(e2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.map, received: n}), INVALID;
    var o = this._def.keyType, a = this._def.valueType, e2 = e2, r2 = new Map(), i = createTasks(t), s = false, u = function(e3, n2) {
      isAsync(e3) || isAsync(n2) ? i != null && i.push(PseudoPromise.all([e3, n2]).promise.then(function(e4) {
        var n3 = __read(e4, 2), e4 = n3[0], n3 = n3[1];
        return u(e4, n3);
      })) : isInvalid(e3) || isInvalid(n2) ? s = true : r2.set(e3.value, n2.value);
    };
    return __spreadArray([], __read(e2.entries())).forEach(function(e3, n2) {
      var r3 = __read(e3, 2), e3 = r3[0], r3 = r3[1], n2 = t.stepInto(n2), e3 = o._parse(n2.stepInto("key"), e3, getParsedType(e3)), r3 = a._parse(n2.stepInto("value"), r3, getParsedType(r3));
      u(e3, r3);
    }), i !== null && 0 < i.length ? ASYNC(Promise.all(i).then(function() {
      return s ? INVALID : OK(r2);
    })) : s ? INVALID : OK(r2);
  }, r.create = function(e2, n) {
    return new r({valueType: n, keyType: e2, typeName: ZodFirstPartyTypeKind.ZodMap});
  }, r;
}(ZodType), ZodSet = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(r, e2, n2) {
    if (n2 !== ZodParsedType.set)
      return r.addIssue(e2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.set, received: n2}), INVALID;
    var e2 = e2, t = this._def.valueType, o = new Set(), a = createTasks(r), i = false, s = function(e3) {
      isOk(e3) ? o.add(e3.value) : isInvalid(e3) ? i = true : a != null && a.push(e3.promise.then(function(e4) {
        return s(e4);
      }));
    };
    return __spreadArray([], __read(e2.values())).forEach(function(e3, n3) {
      return s(t._parse(r.stepInto(n3), e3, getParsedType(e3)));
    }), a !== null && 0 < a.length ? ASYNC(Promise.all(a).then(function() {
      return i ? INVALID : OK(o);
    })) : i ? INVALID : OK(o);
  }, n.create = function(e2) {
    return new n({valueType: e2, typeName: ZodFirstPartyTypeKind.ZodSet});
  }, n;
}(ZodType), ZodFunction = function(e) {
  function t() {
    var r = e !== null && e.apply(this, arguments) || this;
    return r.args = function() {
      for (var e2 = [], n = 0; n < arguments.length; n++)
        e2[n] = arguments[n];
      return new t(__assign(__assign({}, r._def), {args: ZodTuple.create(e2)}));
    }, r.returns = function(e2) {
      return new t(__assign(__assign({}, r._def), {returns: e2}));
    }, r.implement = function(e2) {
      return r.parse(e2);
    }, r.strictImplement = function(e2) {
      return r.parse(e2);
    }, r.validate = r.implement, r;
  }
  return __extends(t, e), t.prototype._parse = function(r, e2, n) {
    var t2 = this;
    if (n !== ZodParsedType.function)
      return r.addIssue(e2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.function, received: n}), INVALID;
    function s(e3, n2) {
      if (n2.success)
        return n2.data;
      throw n2 = makeIssue(e3, pathToArray(r.path), r.params.errorMap, {code: ZodIssueCode.invalid_arguments, argumentsError: n2.error}), new ZodError([n2]);
    }
    function u(e3, n2) {
      if (n2.success)
        return n2.data;
      throw n2 = makeIssue(e3, pathToArray(r.path), r.params.errorMap, {code: ZodIssueCode.invalid_return_type, returnTypeError: n2.error}), new ZodError([n2]);
    }
    var d = {errorMap: r.params.errorMap}, c = e2;
    return this._def.returns instanceof ZodPromise ? OK(function() {
      for (var i = [], e3 = 0; e3 < arguments.length; e3++)
        i[e3] = arguments[e3];
      return __awaiter(t2, void 0, void 0, function() {
        var n2, r2, t3, o, a;
        return __generator(this, function(e4) {
          switch (e4.label) {
            case 0:
              return n2 = s, r2 = [i], [4, this._def.args.spa(i, d)];
            case 1:
              return t3 = n2.apply(void 0, r2.concat([e4.sent()])), [4, c.apply(void 0, __spreadArray([], __read(t3)))];
            case 2:
              return t3 = e4.sent(), o = u, a = [t3], [4, this._def.returns.spa(t3, d)];
            case 3:
              return [2, o.apply(void 0, a.concat([e4.sent()]))];
          }
        });
      });
    }) : OK(function() {
      for (var e3 = [], n2 = 0; n2 < arguments.length; n2++)
        e3[n2] = arguments[n2];
      var r2 = s(e3, t2._def.args.safeParse(e3, d)), r2 = c.apply(void 0, __spreadArray([], __read(r2)));
      return u(r2, t2._def.returns.safeParse(r2, d));
    });
  }, t.prototype.parameters = function() {
    return this._def.args;
  }, t.prototype.returnType = function() {
    return this._def.returns;
  }, t.create = function(e2, n) {
    return new t({args: e2 || ZodTuple.create([]), returns: n || ZodUnknown.create(), typeName: ZodFirstPartyTypeKind.ZodFunction});
  }, t;
}(ZodType), ZodLazy = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), Object.defineProperty(n.prototype, "schema", {get: function() {
    return this._def.getter();
  }, enumerable: false, configurable: true}), n.prototype._parse = function(e2, n2, r) {
    return this._def.getter()._parse(e2, n2, r);
  }, n.create = function(e2) {
    return new n({getter: e2, typeName: ZodFirstPartyTypeKind.ZodLazy});
  }, n;
}(ZodType), ZodLiteral = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return n2 !== this._def.value ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_type, expected: this._def.value, received: n2}), INVALID) : OK(n2);
  }, Object.defineProperty(n.prototype, "value", {get: function() {
    return this._def.value;
  }, enumerable: false, configurable: true}), n.create = function(e2) {
    return new n({value: e2, typeName: ZodFirstPartyTypeKind.ZodLiteral});
  }, n;
}(ZodType), ZodEnum = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return this._def.values.indexOf(n2) === -1 ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_enum_value, options: this._def.values}), INVALID) : OK(n2);
  }, Object.defineProperty(n.prototype, "options", {get: function() {
    return this._def.values;
  }, enumerable: false, configurable: true}), Object.defineProperty(n.prototype, "enum", {get: function() {
    var n2, e2, r = {};
    try {
      for (var t = __values(this._def.values), o = t.next(); !o.done; o = t.next()) {
        var a = o.value;
        r[a] = a;
      }
    } catch (e3) {
      n2 = {error: e3};
    } finally {
      try {
        o && !o.done && (e2 = t.return) && e2.call(t);
      } finally {
        if (n2)
          throw n2.error;
      }
    }
    return r;
  }, enumerable: false, configurable: true}), Object.defineProperty(n.prototype, "Values", {get: function() {
    var n2, e2, r = {};
    try {
      for (var t = __values(this._def.values), o = t.next(); !o.done; o = t.next()) {
        var a = o.value;
        r[a] = a;
      }
    } catch (e3) {
      n2 = {error: e3};
    } finally {
      try {
        o && !o.done && (e2 = t.return) && e2.call(t);
      } finally {
        if (n2)
          throw n2.error;
      }
    }
    return r;
  }, enumerable: false, configurable: true}), Object.defineProperty(n.prototype, "Enum", {get: function() {
    var n2, e2, r = {};
    try {
      for (var t = __values(this._def.values), o = t.next(); !o.done; o = t.next()) {
        var a = o.value;
        r[a] = a;
      }
    } catch (e3) {
      n2 = {error: e3};
    } finally {
      try {
        o && !o.done && (e2 = t.return) && e2.call(t);
      } finally {
        if (n2)
          throw n2.error;
      }
    }
    return r;
  }, enumerable: false, configurable: true}), n.create = function(e2) {
    return new n({values: e2, typeName: ZodFirstPartyTypeKind.ZodEnum});
  }, n;
}(ZodType), ZodNativeEnum = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    var t = util.getValidEnumValues(this._def.values);
    return t.indexOf(n2) === -1 ? (e2.addIssue(n2, {code: ZodIssueCode.invalid_enum_value, options: util.objectValues(t)}), INVALID) : OK(n2);
  }, n.create = function(e2) {
    return new n({values: e2, typeName: ZodFirstPartyTypeKind.ZodNativeEnum});
  }, n;
}(ZodType), ZodPromise = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(n2, e2, r) {
    var t = this;
    if (r !== ZodParsedType.promise && n2.params.async === false)
      return n2.addIssue(e2, {code: ZodIssueCode.invalid_type, expected: ZodParsedType.promise, received: r}), INVALID;
    e2 = r === ZodParsedType.promise ? e2 : Promise.resolve(e2);
    return OK(e2.then(function(e3) {
      return t._def.type.parseAsync(e3, {path: pathToArray(n2.path), errorMap: n2.params.errorMap});
    }));
  }, n.create = function(e2) {
    return new n({type: e2, typeName: ZodFirstPartyTypeKind.ZodPromise});
  }, n;
}(ZodType), ZodEffects = function(n) {
  function r(e) {
    return n.call(this, e) || this;
  }
  return __extends(r, n), r.prototype.innerType = function() {
    return this._def.schema;
  }, r.prototype._parse = function(n2, r2, e) {
    function t(r3, e2) {
      switch (e2.type) {
        case "refinement":
          var n3 = e2.refinement(r3, i);
          if (n3 instanceof Promise) {
            if (o)
              throw new Error("You can't use .parse() on a schema containing async refinements. Use .parseAsync instead.");
            return n3.then(function(e3) {
              var n4 = i.issueFound;
              return s = s || n4, r3;
            });
          }
          n3 = i.issueFound;
          return s = s || n3, r3;
        case "transform":
          n3 = e2.transform(r3);
          if (n3 instanceof Promise && o)
            throw new Error("You can't use .parse() on a schema containing async transformations. Use .parseAsync instead.");
          return n3;
        default:
          throw new Error("Invalid effect type.");
      }
    }
    var o = n2.params.async === false, a = this._def.effects || [], i = {issueFound: false, addIssue: function(e2) {
      this.issueFound = true, n2.addIssue(r2, e2);
    }, get path() {
      return pathToArray(n2.path);
    }}, s = false;
    if (o) {
      var u = this._def.schema._parseSync(n2, r2, e);
      if (isOk(u)) {
        u = a.reduce(t, u.value);
        return s ? INVALID : OK(u);
      }
      return INVALID;
    }
    function d(e2) {
      return (e2 = a.reduce(function(e3, n3) {
        return e3 instanceof Promise ? e3.then(function(e4) {
          return t(e4, n3);
        }) : t(e3, n3);
      }, e2)) instanceof Promise ? ASYNC(e2.then(function(e3) {
        return s ? INVALID : OK(e3);
      })) : s ? INVALID : OK(e2);
    }
    e = this._def.schema._parse(n2, r2, e);
    return isOk(e) ? d(e.value) : isInvalid(e) ? INVALID : ASYNC(e.promise.then(function(e2) {
      if (isInvalid(e2))
        return INVALID;
      e2 = d(e2.value);
      return isAsync(e2) ? e2.promise : e2;
    }));
  }, r.create = function(e) {
    return new r({schema: e, typeName: ZodFirstPartyTypeKind.ZodEffects});
  }, r;
}(ZodType), ZodOptional = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r === ZodParsedType.undefined ? OK(void 0) : this._def.innerType._parse(e2, n2, r);
  }, n.prototype.unwrap = function() {
    return this._def.innerType;
  }, n.create = function(e2) {
    return new n({innerType: e2, typeName: ZodFirstPartyTypeKind.ZodOptional});
  }, n;
}(ZodType), ZodNullable = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r === ZodParsedType.null ? OK(null) : this._def.innerType._parse(e2, n2, r);
  }, n.prototype.unwrap = function() {
    return this._def.innerType;
  }, n.create = function(e2) {
    return new n({innerType: e2, typeName: ZodFirstPartyTypeKind.ZodNullable});
  }, n;
}(ZodType), ZodDefault = function(e) {
  function n() {
    return e !== null && e.apply(this, arguments) || this;
  }
  return __extends(n, e), n.prototype._parse = function(e2, n2, r) {
    return r === ZodParsedType.undefined && (n2 = this._def.defaultValue()), this._def.innerType._parse(e2, n2, getParsedType(n2));
  }, n.prototype.removeDefault = function() {
    return this._def.innerType;
  }, n.create = function(e2) {
    return new ZodOptional({innerType: e2, typeName: ZodFirstPartyTypeKind.ZodOptional});
  }, n;
}(ZodType), custom = function(e, n) {
  return e ? ZodAny.create().refine(e, n) : ZodAny.create();
}, late = {object: ZodObject.lazycreate};
!function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodPromise = "ZodPromise";
}(ZodFirstPartyTypeKind = ZodFirstPartyTypeKind || {});
var instanceOfType = function(n, e) {
  return e === void 0 && (e = {message: "Input not instance of " + n.name}), custom(function(e2) {
    return e2 instanceof n;
  }, e);
}, stringType = ZodString.create, numberType = ZodNumber.create, bigIntType = ZodBigInt.create, booleanType = ZodBoolean.create, dateType = ZodDate.create, undefinedType = ZodUndefined.create, nullType = ZodNull.create, anyType = ZodAny.create, unknownType = ZodUnknown.create, neverType = ZodNever.create, voidType = ZodVoid.create, arrayType = ZodArray.create, objectType = ZodObject.create, strictObjectType = ZodObject.strictCreate, unionType = ZodUnion.create, intersectionType = ZodIntersection.create, tupleType = ZodTuple.create, recordType = ZodRecord.create, mapType = ZodMap.create, setType = ZodSet.create, functionType = ZodFunction.create, lazyType = ZodLazy.create, literalType = ZodLiteral.create, enumType = ZodEnum.create, nativeEnumType = ZodNativeEnum.create, promiseType = ZodPromise.create, effectsType = ZodEffects.create, optionalType = ZodOptional.create, nullableType = ZodNullable.create, ostring = function() {
  return stringType().optional();
}, onumber = function() {
  return numberType().optional();
}, oboolean = function() {
  return booleanType().optional();
}, external = Object.freeze({__proto__: null, ZodParsedType, getParsedType, makeIssue, EMPTY_PATH, pathToArray, pathFromArray, ParseContext, createRootContext: createRootContext$1, INVALID, OK, ASYNC, isInvalid, isOk, isAsync, ZodType, ZodString, ZodNumber, ZodBigInt, ZodBoolean, ZodDate, ZodUndefined, ZodNull, ZodAny, ZodUnknown, ZodNever, ZodVoid, ZodArray, ZodNonEmptyArray, get objectUtil() {
  return objectUtil;
}, mergeObjects, ZodObject, ZodUnion, ZodIntersection, ZodTuple, ZodRecord, ZodMap, ZodSet, ZodFunction, ZodLazy, ZodLiteral, ZodEnum, ZodNativeEnum, ZodPromise, ZodEffects, ZodTransformer: ZodEffects, ZodOptional, ZodNullable, ZodDefault, custom, Schema: ZodType, ZodSchema: ZodType, late, get ZodFirstPartyTypeKind() {
  return ZodFirstPartyTypeKind;
}, any: anyType, array: arrayType, bigint: bigIntType, boolean: booleanType, date: dateType, effect: effectsType, enum: enumType, function: functionType, instanceof: instanceOfType, intersection: intersectionType, lazy: lazyType, literal: literalType, map: mapType, nativeEnum: nativeEnumType, never: neverType, null: nullType, nullable: nullableType, number: numberType, object: objectType, oboolean, onumber, optional: optionalType, ostring, promise: promiseType, record: recordType, set: setType, strictObject: strictObjectType, string: stringType, transformer: effectsType, tuple: tupleType, undefined: undefinedType, union: unionType, unknown: unknownType, void: voidType, ZodIssueCode, quotelessJson, ZodError, defaultErrorMap, get overrideErrorMap() {
  return overrideErrorMap;
}, setErrorMap});

export { external as z };
