import React from "../../../_snowpack/pkg/react.js";
import {
  Stack,
  Image,
  Text,
  Box
} from "../../../_snowpack/pkg/@chakra-ui/react.js";
import {bookmarks} from "./bookmarks.js";
import {motion} from "../../../_snowpack/pkg/framer-motion.js";
import {resolveProgressString} from "../../utils.js";
const Bookmark = ({bookmark}) => /* @__PURE__ */ React.createElement(Stack, {
  direction: "row",
  py: ["2", "3"],
  pl: ["2", "3"],
  pr: ["3", "6"],
  align: "center",
  bgColor: "gray.800",
  borderColor: "rgba(255, 255, 255, 0.05)",
  borderWidth: "1px",
  borderRadius: "12px"
}, /* @__PURE__ */ React.createElement(Stack, {
  h: "12",
  direction: "row",
  flex: "1",
  align: "center"
}, /* @__PURE__ */ React.createElement(Image, {
  h: "12",
  w: "12",
  objectFit: "cover",
  src: bookmark.imageUrl,
  borderRadius: "6px"
}), /* @__PURE__ */ React.createElement(Stack, {
  spacing: "0",
  justify: "center",
  overflow: "hidden"
}, /* @__PURE__ */ React.createElement(Text, {
  fontSize: ["sm", "md"],
  fontWeight: "semibold",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
}, bookmark.artist), /* @__PURE__ */ React.createElement(Text, {
  color: "gray.400",
  fontSize: ["xs", "sm"],
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
}, bookmark.title))), /* @__PURE__ */ React.createElement(Stack, {
  direction: "row",
  justify: "end"
}, /* @__PURE__ */ React.createElement(Text, {
  color: "gray.400",
  fontSize: ["sm", "md"]
}, resolveProgressString(bookmark.progressMs))));
const List = ({initial, animate}) => /* @__PURE__ */ React.createElement(Stack, {
  as: motion.div,
  w: "100%",
  spacing: "4",
  pb: "4",
  position: "absolute",
  initial,
  animate
}, bookmarks.map((bookmark) => /* @__PURE__ */ React.createElement(Bookmark, {
  bookmark
})));
export const BookmarkCarousel = (props) => {
  const duration = 10;
  return /* @__PURE__ */ React.createElement(Box, {
    overflow: "hidden",
    h: "100%",
    w: "100%",
    position: "relative",
    ...props
  }, /* @__PURE__ */ React.createElement(List, {
    initial: {y: 0},
    animate: {
      y: "-100%",
      transition: {repeat: Infinity, duration, ease: "linear"}
    }
  }), /* @__PURE__ */ React.createElement(List, {
    initial: {y: "100%"},
    animate: {
      y: "0%",
      transition: {repeat: Infinity, duration, ease: "linear"}
    }
  }));
};
