import React from "../_snowpack/pkg/react.js";
import {BrowserRouter as Router, Switch, Route} from "../_snowpack/pkg/react-router-dom.js";
import {Home} from "./Home.js";
import {LoginCallback} from "./LoginCallback.js";
import {ChakraProvider} from "../_snowpack/pkg/@chakra-ui/react.js";
import {theme} from "./theme/theme.js";
import {Privacy} from "./Privacy.js";
const App = ({config}) => {
  return /* @__PURE__ */ React.createElement(ChakraProvider, {
    theme
  }, /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/login-callback"
  }, /* @__PURE__ */ React.createElement(LoginCallback, null)), /* @__PURE__ */ React.createElement(Route, {
    path: "/privacy"
  }, /* @__PURE__ */ React.createElement(Privacy, null)), /* @__PURE__ */ React.createElement(Route, {
    path: "/"
  }, /* @__PURE__ */ React.createElement(Home, {
    config
  })))));
};
export default App;
