import React, {useState} from "../_snowpack/pkg/react.js";
import {Root} from "./components/Root.js";
import {Header} from "./components/Header.js";
import {MenuModal} from "./components/MenuModal.js";
import {BookmarkList} from "./components/BookmarkList.js";
import {PlaybackModal} from "./components/PlaybackModal/PlaybackModal.js";
export const Main = ({config, token, onLogout}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  return /* @__PURE__ */ React.createElement(Root, null, /* @__PURE__ */ React.createElement(BookmarkList, {
    config,
    token
  }), /* @__PURE__ */ React.createElement(Header, {
    onMenuOpen: () => setMenuOpen(true)
  }), /* @__PURE__ */ React.createElement(PlaybackModal, {
    config,
    token
  }), /* @__PURE__ */ React.createElement(MenuModal, {
    isOpen: isMenuOpen,
    onClose: () => setMenuOpen(false),
    onLogout
  }));
};
