export const getToken = () => localStorage.getItem("token");
export const setToken = (token) => localStorage.setItem("token", token);
export const getTokenExpiresAt = () => {
  const val = localStorage.getItem("tokenExpiresAt");
  if (val && parseInt(val, 10) > 0) {
    return parseInt(val, 10);
  }
  return null;
};
export const setTokenExpiresAt = (tokenExpiresAt) => localStorage.setItem("tokenExpiresAt", tokenExpiresAt.toString());
export const setGrantedAccess = () => {
  localStorage.setItem("grantedAccess", "true");
};
export const getGrantedAccess = () => {
  localStorage.getItem("grantedAccess");
};
