import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import React from "../_snowpack/pkg/react.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import App from "./App.js";
import {QueryClient, QueryClientProvider} from "../_snowpack/pkg/react-query.js";
import "../_snowpack/pkg/focus-visible/dist/focus-visible.js";
const queryClient = new QueryClient();
const getConfigProperty = (property) => {
  const val = __SNOWPACK_ENV__[property];
  if (val === void 0) {
    throw new Error(`Missing config property "${property}"`);
  }
  return val;
};
const config = {
  clientId: getConfigProperty("SNOWPACK_PUBLIC_CLIENT_ID"),
  apiUrl: getConfigProperty("SNOWPACK_PUBLIC_API_URL")
};
ReactDOM.render(/* @__PURE__ */ React.createElement(React.StrictMode, null, /* @__PURE__ */ React.createElement(QueryClientProvider, {
  client: queryClient
}, /* @__PURE__ */ React.createElement(App, {
  config
}))), document.getElementById("root"));
if (undefined /* [snowpack] import.meta.hot */ ) {
  undefined /* [snowpack] import.meta.hot */ .accept();
}
