import React, {useEffect} from "../_snowpack/pkg/react.js";
import {Redirect, useLocation} from "../_snowpack/pkg/react-router-dom.js";
import * as storage from "./storage.js";
export const LoginCallback = () => {
  const {hash} = useLocation();
  const hashParams = new URLSearchParams(hash.slice(1));
  const params = {
    token: hashParams.get("access_token"),
    expiresIn: hashParams.get("expires_in")
  };
  const tokenExpiresAt = params.expiresIn && parseInt(params.expiresIn, 10) > 0 ? Date.now() + parseInt(params.expiresIn, 10) * 1e3 : null;
  useEffect(() => {
    if (params.token) {
      storage.setToken(params.token);
    }
    if (tokenExpiresAt) {
      storage.setTokenExpiresAt(tokenExpiresAt);
    }
  }, [params.token, tokenExpiresAt]);
  return /* @__PURE__ */ React.createElement(Redirect, {
    to: "/"
  });
};
