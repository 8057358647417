import {extendTheme} from "../../_snowpack/pkg/@chakra-ui/react.js";
export const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        backgroundColor: "gray.900"
      }
    }
  },
  config: {
    initialColorMode: "dark"
  }
});
