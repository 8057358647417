import React from "../../_snowpack/pkg/react.js";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Icon,
  Stack,
  Text,
  Link,
  Flex,
  Image,
  Center
} from "../../_snowpack/pkg/@chakra-ui/react.js";
import {
  ArrowRightOnRectangleIcon,
  UserIcon
} from "../../_snowpack/pkg/@heroicons/react/24/outline.js";
import {FaTwitter} from "../../_snowpack/pkg/react-icons/fa.js";
import {useQuery} from "../../_snowpack/pkg/react-query.js";
import {spotifyApi} from "../spotifyApi.js";
import {resolveImageUrl} from "../utils.js";
export const MenuModal = ({isOpen, onClose, onLogout}) => {
  const {data: user} = useQuery("user", () => spotifyApi.getMe());
  const images = user?.images;
  const imageUrl = images ? resolveImageUrl(images) : "";
  return /* @__PURE__ */ React.createElement(Modal, {
    isOpen,
    onClose,
    isCentered: true,
    motionPreset: "slideInBottom"
  }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, {
    rounded: "xl",
    minHeight: "md",
    bottom: "0",
    bg: "gray.800"
  }, /* @__PURE__ */ React.createElement(ModalHeader, null, /* @__PURE__ */ React.createElement(Heading, {
    size: "md",
    textAlign: "center"
  }, "Settings")), /* @__PURE__ */ React.createElement(ModalCloseButton, null), /* @__PURE__ */ React.createElement(ModalBody, null, /* @__PURE__ */ React.createElement(Tabs, {
    colorScheme: "green",
    isFitted: true,
    variant: "line"
  }, /* @__PURE__ */ React.createElement(TabList, null, ["Account", "About"].map((tab) => /* @__PURE__ */ React.createElement(Tab, {
    key: tab
  }, tab))), /* @__PURE__ */ React.createElement(TabPanels, null, /* @__PURE__ */ React.createElement(TabPanel, {
    paddingX: "0",
    paddingY: "8"
  }, /* @__PURE__ */ React.createElement(Stack, {
    spacing: "8"
  }, /* @__PURE__ */ React.createElement(Stack, {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }, /* @__PURE__ */ React.createElement(Stack, {
    direction: "row",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Image, {
    href: imageUrl,
    h: "10",
    w: "10",
    rounded: "full",
    fallback: /* @__PURE__ */ React.createElement(Center, {
      h: "10",
      w: "10",
      bg: "gray.600",
      rounded: "full"
    }, /* @__PURE__ */ React.createElement(Icon, {
      color: "gray.400",
      as: UserIcon,
      h: "5",
      w: "5"
    }))
  }), /* @__PURE__ */ React.createElement(Text, {
    fontWeight: "semibold"
  }, user?.display_name)), /* @__PURE__ */ React.createElement(Button, {
    textColor: "white",
    leftIcon: /* @__PURE__ */ React.createElement(Icon, {
      color: "gray.200",
      as: ArrowRightOnRectangleIcon,
      h: "5",
      w: "5"
    }),
    loadingText: "Logging out",
    onClick: onLogout,
    variant: "solid"
  }, "Log out")), /* @__PURE__ */ React.createElement(Stack, null, /* @__PURE__ */ React.createElement(Text, {
    fontSize: "md",
    fontWeight: "semibold"
  }, "Log in with a different Spotify account?"), /* @__PURE__ */ React.createElement(Text, {
    fontSize: "sm",
    color: "gray.400"
  }, "Go to", " ", /* @__PURE__ */ React.createElement(Link, {
    href: "https://accounts.spotify.com",
    isExternal: true,
    textDecoration: "underline"
  }, "accounts.spotify.com"), " ", "and press Log Out. Then return to", " ", /* @__PURE__ */ React.createElement(Link, {
    href: "https://spotifybookmarks.app",
    textDecoration: "underline"
  }, "spotifybookmarks.app"), " ", "and log in with a different account.")))), /* @__PURE__ */ React.createElement(TabPanel, {
    paddingX: "0",
    paddingY: "8"
  }, /* @__PURE__ */ React.createElement(Stack, {
    spacing: "4"
  }, /* @__PURE__ */ React.createElement(Text, null, "Made by Hugo Cárdenas."), /* @__PURE__ */ React.createElement(Text, null, "For any feedback, comments or ideas, please reach out on Twitter."), /* @__PURE__ */ React.createElement(Link, {
    href: "https://twitter.com/_hugocardenas",
    isExternal: true,
    _hover: {
      textDecoration: "none"
    }
  }, /* @__PURE__ */ React.createElement(Stack, {
    alignItems: "center",
    bg: "rgba(255, 255, 255, 0.025)",
    padding: "6",
    rounded: "lg",
    _hover: {
      bg: "rgba(255, 255, 255, 0.05)"
    }
  }, /* @__PURE__ */ React.createElement(Flex, {
    justifyContent: "center",
    alignItems: "center",
    h: "12",
    w: "12",
    bg: "blue.400",
    rounded: "full"
  }, /* @__PURE__ */ React.createElement(Icon, {
    as: FaTwitter,
    h: "6",
    w: "6",
    color: "gray.200"
  })), /* @__PURE__ */ React.createElement(Text, null, "@_hugocardenas"))))))))));
};
