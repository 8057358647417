import {
  Container,
  Heading,
  Stack,
  Text,
  UnorderedList,
  ListItem,
  Link
} from "../_snowpack/pkg/@chakra-ui/react.js";
import React from "../_snowpack/pkg/react.js";
import {scopes} from "./config.js";
import {Link as RouterLink} from "../_snowpack/pkg/react-router-dom.js";
const TextBlock = ({children}) => /* @__PURE__ */ React.createElement(Text, {
  color: "gray.200"
}, children);
export const Privacy = () => {
  return /* @__PURE__ */ React.createElement(Container, {
    paddingY: "16"
  }, /* @__PURE__ */ React.createElement(Stack, {
    spacing: "6"
  }, /* @__PURE__ */ React.createElement(Heading, {
    size: "xl"
  }, "Privacy Policy"), /* @__PURE__ */ React.createElement(TextBlock, null, "This Privacy Policy was last updated on 11th August 2021."), /* @__PURE__ */ React.createElement(TextBlock, null, "Spotify Bookmarks stores your Spotify user id. It also stores Spotify information of the tracks which you create bookmarks for. Data is stored on servers within the EU and as such Spotify Bookmarks is bound to EU data laws and practises such as GDPR."), /* @__PURE__ */ React.createElement(TextBlock, null, "It asks for the following Spotify scopes, to be able to create bookmarks based on your current playback, and to make shortcuts to specific points in time of tracks:", /* @__PURE__ */ React.createElement(UnorderedList, null, scopes.map((scope) => /* @__PURE__ */ React.createElement(ListItem, {
    key: scope
  }, scope)))), /* @__PURE__ */ React.createElement(TextBlock, null, "Spotify Bookmarks uses Plausible Analytics."), /* @__PURE__ */ React.createElement(Link, {
    as: RouterLink,
    to: "/"
  }, "← Back")));
};
