import React from "../../_snowpack/pkg/react.js";
import {
  Box,
  Stack,
  Icon,
  IconButton,
  Text,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  useToast,
  Center,
  useMediaQuery
} from "../../_snowpack/pkg/@chakra-ui/react.js";
import {EllipsisHorizontalIcon} from "../../_snowpack/pkg/@heroicons/react/24/outline.js";
import {PlayIcon} from "../../_snowpack/pkg/@heroicons/react/24/solid.js";
import {getApi} from "../api/api.js";
import {spacing} from "../style.js";
import {resolveImageUrl, resolveProgressString} from "../utils.js";
import {useMutation, useQueryClient} from "../../_snowpack/pkg/react-query.js";
import {motion} from "../../_snowpack/pkg/framer-motion.js";
export const Bookmark = ({
  config,
  token,
  bookmark,
  onPlayBookmark
}) => {
  const toast = useToast();
  const [isLargeScreen] = useMediaQuery("(min-width: 768px)");
  const {deleteBookmark} = getApi({
    baseUrl: config.apiUrl,
    token
  });
  const queryClient = useQueryClient();
  const {mutate: handleDeleteBookmark, isLoading: isDeletingBookmark} = useMutation(() => deleteBookmark(bookmark.id), {
    onSuccess: () => {
      queryClient.invalidateQueries("bookmarks");
    },
    onError: (err) => {
      toast({
        status: "error",
        title: "Failed to delete bookmark",
        ...err.responseText ? {description: err.responseText} : {},
        isClosable: true
      });
    }
  });
  return /* @__PURE__ */ React.createElement(Stack, {
    as: motion.div,
    h: ["16", "20"],
    w: "full",
    direction: "row",
    rounded: "xl",
    initial: {
      border: "1px solid rgba(0,0,0,0)",
      background: "transparent",
      borderColor: "transparent"
    },
    whileHover: isLargeScreen ? {
      border: "1px solid rgba(255, 255, 255,0.1)",
      backgroundColor: "rgba(255, 255, 255, 0.025)",
      transition: {duration: 0.2}
    } : void 0,
    whileTap: {
      backgroundColor: "rgba(255, 255, 255, 0.050)",
      transition: {duration: 0.05}
    },
    spacing: "0",
    overflow: "hidden"
  }, /* @__PURE__ */ React.createElement(Stack, {
    as: motion.div,
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    h: "full",
    paddingLeft: "4",
    spacing,
    overflowX: "hidden",
    flexGrow: 1,
    role: "group",
    _hover: {
      cursor: "pointer"
    },
    whileHover: isLargeScreen ? "hover" : void 0,
    onClick: () => onPlayBookmark(bookmark)
  }, /* @__PURE__ */ React.createElement(Stack, {
    direction: "row",
    overflowX: "hidden",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Box, {
    backgroundColor: "gray.500",
    h: "12",
    w: "12",
    rounded: "md",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexShrink: 0
  }, /* @__PURE__ */ React.createElement(Image, {
    position: "absolute",
    left: 0,
    right: 0,
    src: resolveImageUrl(bookmark.images),
    overflow: "hidden",
    boxSize: "12",
    rounded: "md",
    fallback: /* @__PURE__ */ React.createElement(Box, {
      h: "12",
      w: "12",
      backgroundColor: "gray.700",
      rounded: "md"
    })
  }), /* @__PURE__ */ React.createElement(Center, {
    as: motion.div,
    display: ["none", "flex"],
    position: "absolute",
    h: "100%",
    w: "100%",
    backgroundColor: "transparent",
    zIndex: 50,
    variants: {
      hover: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        transition: {duration: 0.2}
      }
    }
  }, /* @__PURE__ */ React.createElement(Center, {
    as: motion.div,
    initial: {opacity: 0},
    variants: {
      hover: {opacity: 1, transition: {duration: 0.4}}
    }
  }, /* @__PURE__ */ React.createElement(Icon, {
    as: PlayIcon,
    h: "6",
    w: "6",
    color: "gray.50",
    zIndex: 100
  })))), /* @__PURE__ */ React.createElement(Stack, {
    spacing: "0",
    overflowX: "hidden"
  }, /* @__PURE__ */ React.createElement(Text, {
    color: "gray.100",
    fontSize: ["sm", "md"],
    fontWeight: "medium",
    isTruncated: true,
    userSelect: "none"
  }, bookmark.trackName), /* @__PURE__ */ React.createElement(Text, {
    color: "gray.400",
    fontSize: "sm",
    isTruncated: true,
    userSelect: "none"
  }, bookmark.artistName))), /* @__PURE__ */ React.createElement(Text, {
    color: "gray.400",
    fontSize: ["sm", "md"],
    userSelect: "none"
  }, resolveProgressString(bookmark.progressMs))), /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(MenuButton, {
    disabled: isDeletingBookmark,
    color: "gray.500",
    "aria-label": "Open menu",
    as: IconButton,
    variant: "link",
    icon: isDeletingBookmark ? /* @__PURE__ */ React.createElement(Spinner, {
      h: "5",
      w: "5"
    }) : /* @__PURE__ */ React.createElement(Icon, {
      as: EllipsisHorizontalIcon,
      h: "5",
      w: "5"
    }),
    h: "full",
    width: {
      base: "14",
      md: "16"
    },
    flexShrink: 0,
    _hover: {
      color: "gray.400"
    }
  }), /* @__PURE__ */ React.createElement(MenuList, {
    zIndex: 1e3
  }, /* @__PURE__ */ React.createElement(MenuItem, {
    onClick: () => handleDeleteBookmark()
  }, "Delete"))));
};
