import {z} from "../../_snowpack/pkg/zod.js";
const PlaybackTrack = z.object({
  item: z.object({
    type: z.literal("track"),
    id: z.string(),
    name: z.string(),
    track_number: z.number().min(1),
    artists: z.array(z.object({
      id: z.string(),
      name: z.string()
    })).nonempty(),
    album: z.object({
      id: z.string(),
      name: z.string(),
      images: z.array(z.object({
        height: z.number(),
        width: z.number(),
        url: z.string()
      })).nonempty()
    })
  }),
  progress_ms: z.number()
});
const PlaybackEpisode = z.object({
  item: z.object({
    type: z.literal("episode"),
    id: z.string(),
    name: z.string(),
    show: z.object({
      id: z.string(),
      name: z.string(),
      images: z.array(z.object({
        height: z.number(),
        width: z.number(),
        url: z.string()
      })).nonempty()
    })
  }),
  progress_ms: z.number()
});
const Playback = z.union([PlaybackTrack, PlaybackEpisode]);
export const resolvePlayback = (response) => {
  try {
    const validPlayback = Playback.parse(response, {});
    const {item, progress_ms} = validPlayback;
    switch (item.type) {
      case "track":
        return {
          type: "track",
          trackId: item.id,
          trackName: item.name,
          artistId: item.artists[0].id,
          artistName: item.artists[0].name,
          images: item.album.images,
          progressMs: progress_ms
        };
      case "episode":
        return {
          type: "episode",
          trackId: item.id,
          trackName: item.name,
          artistId: item.show.id,
          artistName: item.show.name,
          images: item.show.images,
          progressMs: progress_ms
        };
    }
  } catch (err) {
    return "invalid";
  }
};
