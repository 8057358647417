import {Box, Image, Stack, Text} from "../../../_snowpack/pkg/@chakra-ui/react.js";
import React from "../../../_snowpack/pkg/react.js";
export const Track = ({imageUrl, trackName, artistName}) => /* @__PURE__ */ React.createElement(Stack, {
  direction: "row",
  alignItems: "center",
  flexGrow: 1,
  spacing: "2",
  overflowX: "hidden"
}, /* @__PURE__ */ React.createElement(Image, {
  objectFit: "cover",
  src: imageUrl,
  h: "12",
  w: "12",
  flexShrink: 0,
  rounded: "md",
  fallback: /* @__PURE__ */ React.createElement(Box, {
    minH: "12",
    minW: "12",
    backgroundColor: "gray.700",
    rounded: "md"
  })
}), /* @__PURE__ */ React.createElement(Stack, {
  justify: "center",
  spacing: "0",
  overflowX: "hidden"
}, /* @__PURE__ */ React.createElement(Text, {
  color: "gray.100",
  fontSize: "md",
  fontWeight: "medium",
  isTruncated: true
}, trackName), /* @__PURE__ */ React.createElement(Text, {
  color: "gray.400",
  fontSize: "sm",
  fontWeight: "normal",
  isTruncated: true
}, artistName)));
