export const bookmarks = [
  {
    artist: "Jimi Hendrix",
    title: "All along the watchtower",
    progressMs: 65473,
    imageUrl: "/carousel/allalongthewatchtower.jpg"
  },
  {
    artist: "Guns'n'Roses",
    title: "Apetite for destruction",
    progressMs: 124822,
    imageUrl: "/carousel/apetitefordestruction.jpg"
  },
  {
    artist: "Michael Jackson",
    title: "Billie Jean",
    progressMs: 128234,
    imageUrl: "/carousel/billiejean.jpg"
  },
  {
    artist: "Paint it black",
    title: "Rolling Stones",
    progressMs: 45635,
    imageUrl: "/carousel/paintitblack.jpg"
  },
  {
    artist: "Led Zeppelin",
    title: "Whole lotta love",
    progressMs: 84567,
    imageUrl: "/carousel/wholelottalove.jpg"
  }
];
